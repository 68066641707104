import sha256 from 'crypto-js/sha256';
const salt = "gridlock-dev"

export function saveData(type, data) {
    if (type === "demoData") {
        //create a an array of objects that is data.units long, with id being the count from 0 to data.units
        let units = []
        for (let i = 0; i < data.units; i++) {
            units.push({
                    x: i,
                    y: 1,
                    id: `u${i}`,
                    lockBattery: randomPercentage(),
                    name: `Unit ${i + 1}`, 
                    size: randomSize(),
                    status: "unavailable",
                    gridlock: false,
                    user: randomLockUser(),
                    start: Date.now(),
    
                    ...randomLocation()
                })
        }
        //give a random unit n number of true's, where n is data.available
        for (let i = 0; i < data.available; i++) {
            units[i].status = "available"
        }
        //give a random unit n number of true's, where n is data.available
        for (let i = 0; i < data.gridlocks; i++) {
            units[i].gridlock = true
        }
        console.log(units)
        localStorage.setItem("units", JSON.stringify(units))
        localStorage.setItem("demoData", JSON.stringify(data))
        localStorage.setItem("geoJSON", JSON.stringify(createGeoJSON(units)))

    }
}
export function getData(type) {
    //check if data exists
    //check all localStorage keys
    if (!localStorage.getItem(type)) {
        return null
    }
    return JSON.parse(localStorage.getItem(type))
}

function randomPercentage() {
    return Math.floor(Math.random() * 100) + 1
}

function randomSize() {
    const sizes = ["10x10", "20x10", "30x20"]
    return sizes[Math.floor(Math.random() * sizes.length)]
}
function randomPrice() {
    //from 50$ to 200$
    return Math.floor(Math.random() * 150) + 50
}
function randomLockUser() {
    const names = ['Jeff', 'Kevin', 'Delaney', 'Lit', "Perk", 'Manja', 'Rich', 'Kyle', 'Miles']
    const user = {
        fName: names[Math.floor(Math.random() * names.length)],
        lName: names[Math.floor(Math.random() * names.length)],
        email: names[Math.floor(Math.random() * names.length)] + "@bmail.com",
        phoneNumber: "619-222-2222",
    }
    return user
}

function randomLocation() {
    //create random building, floor, facility, address, city, state, zip, country, longitude, latitude
    const buildings = [
        {
            building: "Building 1",
            floors: Math.floor(Math.random() * 5) + 1,
            facility: "Facility 1",
            address: "145 N Beacon St",
            city: "Boston",
            state: "MA",
            zip: "02135",
            country: "USA",
            longitude: "-71.1503",
            latitude: "42.3498"
        },
        {
            building: "Building 2",
            floors: Math.floor(Math.random() * 5) + 1,
            facility: "Facility 2",
            address: "156 Lincoln St",
            city: "Boston",
            state: "MA",
            zip: "02135",
            country: "USA",
            longitude: "-71.139748",
            latitude: "42.358452",
        },
        {
            building: "Building 3",
            floors: Math.floor(Math.random() * 5) + 1,
            facility: "Facility 3",
            address: "1356 Commonwealth Ave",
            city: "Allston",
            state: "MA",
            zip: "02135",
            country: "USA",
            longitude: "-71.136230",
            latitude: "42.347618"
        },
        {
            building: "Building 4",
            floors: Math.floor(Math.random() * 5) + 1,
            facility: "Facility 4",
            address: "5 Station St",
            city: "Brookline",
            state: "MA",
            zip: "02445",
            country: "USA",
            longitude: "-71.118070",
            latitude: "42.332760"
        },
        {
            building: "Building 5",
            floors: Math.floor(Math.random() * 5) + 1,
            facility: "Facility 5",
            address: "460 Somerville Ave",
            city: "Somerville",
            state: "MA",
            zip: "02143",
            country: "USA",
            longitude: "-71.103280",
            latitude: "42.381930"
        },
        {
            building: "Building 6",
            floors: Math.floor(Math.random() * 5) + 1,
            facility: "Facility 6",
            address: "654 Beacon St #4",
            city: "Boston",
            state: "MA",
            zip: "02215",
            country: "USA",
            longitude: "-71.09559300000001",
            latitude: "42.3492825"
        },
    ]
    const choice = buildings[Math.floor(Math.random() * buildings.length)]
    //add a locationId hash
    const locationId = generateLocationId(choice.address, choice.city, choice.state, choice.zip, salt)
    return {
        ...choice,
        locationId,
    }
}

function createGeoJSON(units){
    const unitsByAddress = {};

    units.forEach((unit) => {
      const { address, city, state, zip } = unit;
      const locationId = generateLocationId(address, city, state, zip, salt);
  
      if (!unitsByAddress[locationId]) {
        unitsByAddress[locationId] = {
          locationId,
          address,
          //units: [],
          count: 0,
          longitude: unit.longitude,
          latitude: unit.latitude,
        };
      }
      //unitsByAddress[locationId].units.push(unit);
      unitsByAddress[locationId].count++;
    });
  
    const sortedUnits = Object.values(unitsByAddress);
    sortedUnits.sort((a, b) => a.address.localeCompare(b.address));
  
    return sortedUnits;
  }
  

function generateLocationId(address, city, state, zip, salt) {
    const data = `${address}${city}${state}${zip}${salt}`;
    const hash = sha256(data).toString();
    return hash;
  }
