import './AdminController.css'
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import UnitChart from "../Components/UnitChart"
import UnitList from "../Components/UnitList"
import SideMenu from '../Components/SideMenu'
import AdminPageHeader  from '../Components/AdminPageHeader'
import UnitDetails from '../Components/UnitDetails'
import HomeInfo from '../Components/HomeInfo'
import MapView from '../Components/MapView'
import Loader from '../Components/Loader'
import SettingsPage from '../Components/SettingsData'
import ActivityPage from '../Components/ActivityPage'
import { useNavigate } from 'react-router-dom'
import { getData } from '../Components/LocalStorageFunctions'
import { useParams } from 'react-router-dom'
import { getUser } from '../FirebaseFunctions'
import { UserAuth } from "../Auth/AuthContext";
import Welcome from '../Components/Welcome'

function AdminController(props) {
    console.log(props)
    const { user } = UserAuth();
    console.log(user)
    const navigate = useNavigate()
    const [units, setUnits] = useState(false)
    const [timeFrame, setTimeFrame] = useState(true)
    const [userData, setUserData] = useState(false)
    const demo_data = [
        {
          "x": 1,
          "y": 1,
          "id": 1,
          "lockBattery": 100,
          "name": "Unit 1",
          "size": "10x10",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":false,
        },
        { 
          "x": 1,
          "y": 2,
          "id": 2,
          "lockBattery": 90,
          "name": "Unit 2",
          "size": "10x10",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 3,
          "id": 3,
          "lockBattery": 80,
          "name": "Unit 3",
          "size":"10x10",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 4,
          "id": 4,
          "lockBattery": 70,
          "name": "Unit 4",
          "size": "10x10",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 5,
          "id": 5,
          "lockBattery": 60,
          "name": "Unit 5",
          "size": "10x10",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 6,
          "id": 6,
          "lockBattery": 50,
          "name": "Unit 6",
          "size": "10x20",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 7,
          "lockBattery": 40,
          "name": "Unit 7",
          "size": "10x20",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 8,
          "lockBattery": 30,
          "name": "Unit 8",
          "size": "10x20",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 9,
          "lockBattery": 20,
          "name": "Unit 9",
          "size": "10x40",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 10,
          "lockBattery": 10,
          "name": "Unit 10",
          "size": "10x40",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 11,
          "lockBattery": 0,
          "name": "Unit 11",
          "size": "10x40",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 12,
          "lockBattery": 25,
          "name": "Unit 12",
          "size": "10x40",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 13,
          "lockBattery": 66,
          "name": "Unit 13",
          "size": "10x40",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 14,
          "lockBattery": 45,
          "name": "Unit 14",
          "size": 10,
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 15,
          "lockBattery": 23,
          "name": "Unit 15",
          "size": "10x40",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 16,
          "lockBattery": 78,
          "name": "Unit 16",
          "size": "10x40",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 17,
          "lockBattery": 89,
          "name": "Unit 17",
          "size": "10x40",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 18,
          "lockBattery": 76,
          "name": "Unit 18",
          "size": "10x40",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 19,
          "lockBattery": 83,
          "name": "Unit 19",
          "size": "10x40",
          "price": 100,
          "status": "available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 20,
          "lockBattery": 100,
          "name": "Unit 20",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 21,
          "lockBattery": 55,
          "name": "Unit 21",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 22,
          "lockBattery": 5,
          "name": "Unit 22",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 23,
          "lockBattery": 33,
          "name": "Unit 23",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 24,
          "lockBattery": 66,
          "name": "Unit 24",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 25,
          "lockBattery": 77,
          "name": "Unit 25",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 26,
          "lockBattery": 88,
          "name": "Unit 26",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 27,
          "lockBattery": 99,
          "name": "Unit 27",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 28,
          "lockBattery": 100,
          "name": "Unit 28",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 29,
          "lockBattery": 100,
          "name": "Unit 29",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
        {
          "x": 1,
          "y": 7,
          "id": 30,
          "lockBattery": 100,
          "name": "Unit 30",
          "size": "10x40",
          "price": 100,
          "status": "not available",
          "building": "Building 1",
          "floor": 1,
          "facility": "Facility 1",
          "address": "123 Main St",
          "city": "City",
          "state": "State",
          "zip": "12345",
          "country": "USA",
          "gridlock":true,
        },
    
      ]
    const [unitDetailsView, setUnitDetailsView] = useState(false)
    const [unitView, setUnitView] = useState(props.view==="list"? "list":"grid")
    const [loader, setLoader] = useState(true)

    const handleSelectUnit = (unit) => {
      console.log(unit)
        if (unit === null){
            //if the unit is null, then we are at the top level
            //so we should hide the unit details
            setUnitDetailsView(false)
            navigate(`/locks`)
            return
        }
        //bubble up the unit to the parent
        console.log(unit)
        //also show unit details
        setUnitDetailsView(unit)
        //navigate to the unit details page
        navigate(`/locks/unit/${unit.id}`)
    }
    const handleToggleView = (view) => {
        setUnitView(view)
        console.log(unitDetailsView)
        console.log(view)
        if ((view === "grid") && !unitDetailsView) {
          navigate(`/locks`)
          return
        }
        if ((view === "grid") && unitDetailsView) {
          navigate(`/locks/unit/${unitDetailsView.id}`)
          return
        }
        if ((view === "list") && unitDetailsView) {
          navigate(`/list/unit/${unitDetailsView.id}`)
          return
        }
        else {
          navigate(`/list`)
          return
        }
    }

    
  useEffect(() => {
    const data = getData('units');
    setUnits(data);
    getUser(user.uid).then((data) => {
      if (data) {
        console.log(data)
        setUserData({
          company: data.company,
          orgId: data.orgId,
        })
    }
    setLoader(false)
    })
  }, []);

  useEffect(() => {
    console.log(props)
    setUnitView(props.view==="list"? "list":"grid")
  }, [props.page])

  const handleQuerySearch = (query) => {
    console.log(query)
    if (query.item === "*") {
      setUnits(getData('units'))
      return
    }
    const filteredUnits = units.filter((unit) => {
      return unit[query.field] === query.item
    })
    console.log(filteredUnits)
    setUnits(filteredUnits)
  }

    return (
      <div className="admin-main">
            {
              loader ? <Loader /> : (
              <>
              <SideMenu page={props.page} company={userData.company} orgId={userData.orgId}/>
              
              {
                !(props.welcome === true) ? (
                <>
                {
                    props.page === "Locks" && units &&
                    <div className="container">
                    <AdminPageHeader triggerSelection={(choice) => handleQuerySearch(choice)} units={units} page={props.page} lockView={unitView} toggleView={(view) => handleToggleView(view)}/>
    
                    <div className="unit-chart-box">
                            {
                                unitView === "grid" && units && 
                                <UnitChart units={units} selectUnit={(unit) => handleSelectUnit(unit)}/>
                            }
                            {
                                (unitDetailsView && unitView === "grid") &&
                                <UnitDetails unitData={unitDetailsView}/>
                            }
                            {
                                unitView == "list" && !unitDetailsView &&
                                <UnitList unitData={unitDetailsView} selectUnit={(unit) => handleSelectUnit(unit)}/>
                            
                            }
                            {
                                unitDetailsView && unitView === "list" &&
                                <UnitList unitData={unitDetailsView} selectUnit={(unit) => handleSelectUnit(unit)}/>
                            }
                            
                    </div>
                    
                    
                    </div>
                }
                {
                    props.page === "Home" &&
                    <div className="container overflow-scroll">
                        <AdminPageHeader triggerTime={(selection, text) => setTimeFrame({icon:selection, text:text})} page={props.page}/>
                        <HomeInfo unitData={units} selectedTimeFrame={timeFrame} orgId={userData.orgId}/>
                    </div>
                }
                {
                    props.page === "Map" &&
                    <div className="container">
                        <AdminPageHeader page={props.page}/>
                        <MapView view="map"></MapView>
                    </div>
                }
                {
                  props.page === "Users" && 
                  <div className="container">
                        <AdminPageHeader page={props.page}/>
                  </div>
                }
                {
                  props.page === "Activity" &&
                  <div className="container">
                        <AdminPageHeader page={props.page}/>
                        <ActivityPage orgId={userData.orgId}></ActivityPage>
                  </div>
                }
                {
                  props.page === "Pins" &&
                  <div className="container">
                        <AdminPageHeader page={props.page}/>
                  </div>
                }
                {
                  props.page === "Account" &&
                  <div className="container">
                        <AdminPageHeader page={props.page}/>
                  </div>
                }
                {
                  props.page === "Settings" &&
                  <div className="container">
                        <AdminPageHeader page={props.page}/>
                        <SettingsPage></SettingsPage>
                  </div>
                }
                </>
                ):(
                  <Welcome company={userData.company} orgId={userData.orgId}></Welcome>
                )
              }
            </>)
            }
          
          </div>
    )
}
export default AdminController;

