import React, { useEffect, useRef, useState } from 'react';
import './UnitChart.css';
import {useParams} from "react-router-dom";

const UnitChart = (props) => {
  console.log(props)
  const {id} = useParams();
  const [details, setDetails] = useState(false)
  const [units, setUnits] = useState(props.units);
  const squareSize = 70;
  const squareMargin = 7;
  const [selectedSquare, setSelectedSquare] = useState(null);

  useEffect(() => {
    if (id !== undefined) {
      //find the unit with id === id
      //get the index of that unit
      console.log(id)
      const index = units.findIndex((unit) => {
        return unit.id === id
      })
      console.log(index)
      setSelectedSquare(index)
      props.selectUnit(units[index])
      setDetails(true)
    }
  }, []);

  useEffect(() => {
    setUnits(props.units)
  }, [props.units])
    

  const handleSquareClick = (e, index) => {
    if (selectedSquare === index) {
      // If the clicked square is already zoomed, unzoom it
      setSelectedSquare(null);
      props.selectUnit(null)
      setDetails(false)
    } else {
      const squareId = e.target.id
      const targetElement = document.getElementById(squareId);
      
      setSelectedSquare(index);
      props.selectUnit(units[index])
      setDetails(true)

      if (targetElement) {
        console.log(targetElement)
        console.log("...scrolling to element")
        //scroll and make sure it is in the center
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })

      }
    }
  };

  const handleAddSquare = () => {
    const lastSquare = units[units.length - 1];
    const lastSquareX = lastSquare.x;
    const lastSquareY = lastSquare.y;

    let newSquareX = lastSquareX + 1;
    let newSquareY = lastSquareY;

    if (newSquareX > 3) {
      newSquareX = 1;
      newSquareY += 1;
    }

    const newSquare = { x: newSquareX, y: newSquareY, id: units.length + 1 }; //need id to scroll

    setUnits([...units, newSquare]);
  };
  const renderSquares = () => {
    return units.map((square, index) => {
      const isZoomed = selectedSquare === index;
      
      
      const squareStyle = {
        //width: `${isZoomed ? squareSize * 1.5 : squareSize}px`,
        //height: `${isZoomed ? squareSize * 1.5 : squareSize}px`,
        //backgroundColor: 'rgb(29, 69, 172)',
        //border: `${square.status === "available" ? '2px solid #3ab73a9c':''}`,
        //boxShadow: '0 5px 5px rgba(0, 0, 0, 0.2)',
        //margin: `${isZoomed ? squareMargin * 5 : squareMargin}px`
      };
      

      if (isZoomed) {
        squareStyle.transform = 'scale(1.15)';
      }
      

      return (
        <div
          key={index}
          id = {square.id}
          className={`${isZoomed ? 'zoomed' : ''}`}
          style={squareStyle}
          onClick={(e) => handleSquareClick(e, index)}
        >
          
        <p>{(square.name).split(" ")[1]}</p>
          
       
        </div>
      )
    })
  }
    


  return (
    <div
      className="hexagon-container"
    >
      { units && renderSquares()}
    </div>
  );
};

export default UnitChart;




















  