
import { useEffect, useState } from "react";
import BarGraph from "./BarGraph";
import './ActivityPage.css'
import FacetSearchItem from "./FacetSearchItem";
import { db } from '../FirebaseFunctions'
import { doc, setDoc, query, collection, where, getDocs, orderBy, limit } from "firebase/firestore"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faLock, faUnlock, faUserPlus, faBattery2 } from "@fortawesome/free-solid-svg-icons";
import BatteryPercentage from "./BatteryPercentage";

function ActivityPage(props) {
    console.log(props)
    const [events, setEvents] = useState([])
    const [eventBarData, setEventBarData] = useState([])
    const site = process.env.REACT_APP_INGEST_API
    
    const barEventCount = 30
    const aggregateBy = 1 //in minutes
    const timeRange = 30 //in minutes

    useEffect(() => {
        //fetch sse
        const eventSource = new EventSource(`${site}/stream/activity?orgId=${props.orgId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json", //token
            },
        });
        eventSource.onmessage = (event) => {
            console.log(event)
            const data = JSON.parse(event.data)
            console.log(data)
            //push the new event to the events array
            //add events to the beginning of the array
            setEvents((prevEvents) => {
                return [data, ...prevEvents]
            }
            )
            

        }
        eventSource.onerror = (event) => {
            console.log(event)
        }
        eventSource.onopen = (event) => {
            console.log(event)
        }
        eventSource.onmessageerror = (event) => {
            console.log(event)
        }

        const currentTime = Math.floor(Date.now() / 1000)
        const startTime = currentTime - (timeRange * 60)
        const getEvents = async (timeRange) => {
            //limit to last 20 events
            console.log(`org_${props.orgId}_activity`)
            //query org_id, activity_events, all, limit 20
            const q = query(collection(db, `org_${props.orgId}`, "activity_events", "all"), orderBy("timestamp", "desc"), limit(20));
            const querySnapshot = await getDocs(q);
            const events = []
            querySnapshot.forEach((doc) => {
                console.log(doc.data())
                events.push(doc.data())
                });
                setEvents(events)

            if (events.length > 0) {
                setEventBarData(aggregateEvents(events))
            }
            else{
                console.log("no events")
                createEmptyBarData()    
            }
        }
    getEvents()
         
      }, []);

      
    function createEmptyBarData() {
            const currentTime = Math.floor(Date.now() / 1000)
            const currentMinute = Math.floor(currentTime / (60 * aggregateBy))
            const result = []
            for (let i = 0; i < barEventCount; i++) {
                const oneMinuteBefore = currentMinute - (barEventCount - i)
                const newTimestamp = minuteToTime(oneMinuteBefore)
                const newItem = {
                    timestamp: newTimestamp,
                    utc: oneMinuteBefore,
                    Locks: 0,
                    Unlocks: 0,
                    'Low Battery': 0,
                    'New User': 0
                }
                result.push(newItem)
            }
            setEventBarData(result)
        }

    function aggregateEvents(events) {
            const aggregated = {};
        
            for (const event of events) {
            const minute = Math.floor(event.timestamp / (60 * aggregateBy)); // Convert timestamp to minute
            const action = event.action;
        
            if (!aggregated.hasOwnProperty(minute)) {
                aggregated[minute] = {
                timestamp: minuteToTime(minute),
                utc: minute,
                'Locks': 0,
                'Unlocks': 0,
                'Low Battery': 0,
                'New User': 0
                };
            }
        
            aggregated[minute][action]++;
            }
        
            const result = [];
        
            for (const minute in aggregated) {
            if (aggregated.hasOwnProperty(minute)) {
                result.push(aggregated[minute]);
            }
            }
            if (result.length < barEventCount) {
                //first make sure there are enough data points to fill the bar graph to the current minute
                console.log(result)
                console.log(result[result.length - 1].utc)
                const lastTimestamp = result[result.length - 1].utc
                const currentTime = Math.floor(Date.now() / 1000)
                const currentMinute = Math.floor(currentTime / (60 * aggregateBy))
                const forwardDifference = currentMinute - lastTimestamp
                for (let i = 0; i < forwardDifference; i++) {
                    //get the last event timestamp
                    const lastTimestamp = result[result.length - 1].utc
                    const oneMinuteAfter = lastTimestamp + (aggregateBy )
                    const newTimestamp = minuteToTime(oneMinuteAfter)
                    const newItem = {
                        timestamp: newTimestamp,
                        utc: oneMinuteAfter,
                        Locks: 0,
                        Unlocks: 0,
                        'Low Battery': 0,
                        'New User': 0
                    }
                    result.push(newItem)
                }


                const difference = barEventCount - result.length
                //make difference number of items with 0 values with the correct timestamp that is 1 minute before each item
                for (let i = 0; i < difference; i++) {
                    //get the first event timestamp
                    const firstTimestamp = result[0].utc
                    const oneMinuteBefore = firstTimestamp - (aggregateBy )
                    const newTimestamp = minuteToTime(oneMinuteBefore)
                    const newItem = {
                        timestamp: newTimestamp,
                        utc: oneMinuteBefore,
                        Locks: 0,
                        Unlocks: 0,
                        'Low Battery': 0,
                        'New User': 0
                    }
                    result.unshift(newItem)
                }
            }

            if (result.length > barEventCount) {
                const difference = result.length - barEventCount
                //cut off the first difference number of items from the front
                result.splice(0, difference)
            }
            return result;
        }
        
    function minuteToTime(minute) {
            //turn into date object
            const date = new Date(minute * 60 * 1000);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

        }      

    function actionIcon(action){
        switch(action){
            case "Locks":
                return (<span>
                    <FontAwesomeIcon icon={faLock} className="lockIcon"/>
                    Lock
                    </span>)
            case "Unlocks":
                return (<span>
                    <FontAwesomeIcon icon={faUnlock} className="unlockIcon"/>
                    Unlock
                    </span>)
            case "New User":
                return (<span>
                    <FontAwesomeIcon icon={faUserPlus} className="newUserIcon"/>
                    New User
                    </span>)
            case "Low Battery":
                return (<span>
                    <FontAwesomeIcon icon={faBattery2} className="lowBatteryIcon"/>
                    Low Battery
                    </span>)
            default:
                return <FontAwesomeIcon icon={faLock} />
        }
    }

    function createMessage(action, lockId, unitId, user, facility, timestamp, battery){
        switch(action){
            case "Locks":
                return (
                    <div>
                        <div className="activity-list-message-header">
                            <span className="status-pill">Lock Status</span>
                            <p>Via Lock Update</p>
                        </div>
                        <div className="activity-list-message-body">
                            <p>{user} locked a lock at {facility} for a unit.</p>
                            [Battery: {battery}%]
                            [Lock ID: {lockId}]
                            [Unit ID: {unitId}]
                        </div>
                    </div>
                )
            case "Unlocks":
                return (
                    <div>
                        <div className="activity-list-message-header">
                            <span className="status-pill">Lock Status</span>
                            <p>Via Lock Update</p>
                        </div>
                        <div className="activity-list-message-body">
                            <p>{user} unlocked a lock at {facility} for a unit.</p>
                            <a href={"locks/unit/u169"}>[Lock ID: {lockId}]</a>
                            <a>[Battery: {battery}%]</a>
                            <a>[Unit ID: {unitId}]</a>
                        </div>
                    </div>

                )
            case "New User":
                return (
                    <div>
                        <div className="activity-list-message-header">
                            <span className="status-pill user-status" >User Status</span>
                            <p>Via Account Update</p>
                        </div>
                        <div className="activity-list-message-body">
                            <p>New user {user} was created for {facility}.</p>
                        </div>
                    </div>

                )
            case "Low Battery":
                return (
                    <div>
                        <div className="activity-list-message-header">
                            <span className="status-pill battery-status" >Battery Status</span>
                            <p>Via Lock Update</p>
                        </div>
                        <div className="activity-list-message-body">
                            <p>The battery for lock {lockId} at {facility} is low.</p>
                            <a>[Battery: {battery}%]</a>
                            <a>[Unit ID: {unitId}]</a>
                        </div>
                    </div>
                )
            default:
                return "No message"
        }
    }

    function dateFromTimestamp(timestamp){
        const date = new Date(timestamp * 1000)
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()
        var hours = date.getHours()
        var minutes = date.getMinutes()
        const ampm = hours >= 12 ? 'pm' : 'am';
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (hours > 12) {
            hours = hours - 12
        }

        const datestring = `${month}/${day}/${year} at ${hours}:${minutes} ${ampm}`
        return datestring
    }

      
    const barData = [
        {
            "timestamp": "20:10",
            "Locks": 100,
            "Unlocks": 30,
            "Low Battery": 20,
            "New User": 10
        },
        {
            "timestamp": "20:20",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 10
        },
        {
            "timestamp": "20:30",
            "Locks": 50,
            "Unlocks": 20,
            "Low Battery": 20,
            "New User": 10
        },
        {
            "timestamp": "20:40",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 5
        },
        {
            "timestamp": "20:50",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 5
        },
        {
            "timestamp": "21:00",
            "Locks": 100,
            "Unlocks": 30,
            "Low Battery": 20,
            "New User": 10
        },
        {
            "timestamp": "21:10",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 10
        },
        {
            "timestamp": "21:20",
            "Locks": 50,
            "Unlocks": 20,
            "Low Battery": 20,
            "New User": 10
        },
        {
            "timestamp": "21:30",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 5
        },
        {
            "timestamp": "21:40",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 5
        },
        {
            "timestamp": "21:50",
            "Locks": 100,
            "Unlocks": 30,
            "Low Battery": 20,
            "New User": 10
        },
        {
            "timestamp": "22:00",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 10
        },
        {
            "timestamp": "22:10",
            "Locks": 50,
            "Unlocks": 20,
            "Low Battery": 20,
            "New User": 10
        },
        {
            "timestamp": "22:20",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 5
        },
        {
            "timestamp": "22:30",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 5
        },
        {
            "timestamp": "22:40",
            "Locks": 10,
            "Unlocks": 20,
            "Low Battery": 10,
            "New User": 5
        },
        {
            "timestamp": "22:50",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 20,
            "New User": 10
        },
        {
            "timestamp": "23:00",
            "Locks": 100,
            "Unlocks": 30,
            "Low Battery": 20,
            "New User": 10
        },
        {
            "timestamp": "23:10",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 10
        },
        {
            "timestamp": "23:20",
            "Locks": 50,
            "Unlocks": 20,
            "Low Battery": 20,
            "New User": 10
        },
        {
            "timestamp": "23:30",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 5
        },
        {
            "timestamp": "23:40",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 10,
            "New User": 5
        },
        {
            "timestamp": "23:50",
            "Locks": 10,
            "Unlocks": 20,
            "Low Battery": 10,
            "New User": 5
        },
        {
            "timestamp": "24:00",
            "Locks": 10,
            "Unlocks": 10,
            "Low Battery": 20,
            "New User": 10
        }
      ]

    const dummyData = [
        {
            action: "lock",
            user: "user1",
            location: "location1",
            address: "address",
            //what other info?
        },
        {
            action: "lock",
            user: "user1",
            location: "location1",
            address: "address",
            //what other info?
        },
        {
            action: "lock",
            user: "user1",
            location: "location1",
            address: "address",
            //what other info?
        },
        {
            action: "lock",
            user: "user1",
            location: "location1",
            address: "address",
            //what other info?
        },
    ]
    

    

    return (
        <div className="activity-container">
            <div className="bar-graph-container">
                <BarGraph data={eventBarData} />
            </div>
            <div className="activity-list-container">
                <section className="side-search">
                    <FacetSearchItem topic="Action"/>
                    <FacetSearchItem topic="Facility"/>
                    <FacetSearchItem topic="User"/>
                    <FacetSearchItem topic="Address"/>
                    <FacetSearchItem topic="Other"/>

                </section>
                <section className="activity-list">
                    <div className="activity-list-header">
                        <p>123 Events found</p>
                        <button>
                            <FontAwesomeIcon icon={faCog} />
                            Options
                        </button>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Message</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                events && events.map((event) => {
                                    return (
                                        <tr key={event.eventId}>
                                            <td className="action-th">{actionIcon(event.action)}</td>
                                            <td>
                                                <div className="activity-list-message">
                                                    {createMessage(event.action, event.lockId, event.unitId, event.user, event.facility, event.timestamp, event.batteryPercentage)}
                                                </div>
                                            </td>
                                            <td className="date-th">{dateFromTimestamp(event.timestamp)}</td>
                                        </tr>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </table>
                </section>
            </div>
  
        </div>
    )
}

export default ActivityPage;