import './DropdownItem.css'
import { useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

const DropdownItem = (props) => {
    const inputRef = useRef(null);
    useEffect(() => {
        inputRef.current.addEventListener('blur', handleDropDownOut);
    }, []);

    const [dropdownItems, setDropdownItems] = useState(false)
    const [placeholder, setPlaceholder] = useState("Search")
    const [value, setValue] = useState("")
    const [autocomplete, setAutocomplete] = useState(false)
    const [dropdownData, setDropdownData] = useState(false)

    useEffect(() => {
        const uniqueFields = ['facility', 'building']
        if (uniqueFields.includes(props.field)) {
            setDropdownData(unique(props.data, props.field))
        }
        else {
            setDropdownData(props.data)
        }
    }, [])
    const createAutocomplete = (value, data) => {
        const filteredData = data.filter((item) => {
          const fieldValue = item[props.field].toLowerCase(); // Convert property value to lowercase
          const searchValue = value.toLowerCase(); // Convert search value to lowercase
          return fieldValue.includes(searchValue);
        });
      
        setAutocomplete(filteredData);
        setDropdownItems(true);
      };

    const unique = (arr, field) => {
        //get unique values from array of objects
        //based on field, return array of unique values
        const uniqueValues = [...new Set(arr.map((item) => item[field]))]
        console.log(uniqueValues)
        return uniqueValues
    }
    
    const handleDropDownIn= () => {
        console.log("Dropdown clicked")
        setDropdownItems(true)
    }
    const handleDropDownOut= (event) => { //onBlur={handleDropDownOut} onFocus={handleDropDownIn}
        
        //make sure the event is not coming from ref
        console.log(event)
        if (event.relatedTarget === null) {
             return
        }

        setDropdownItems(false)
        console.log('handled')
    }
    const handleItemSelect = (e, item) =>{
        console.log("selected~!")
        setValue(item)
        setDropdownItems(false)
        props.bubbleUpSelection({item:item, field:props.field})
    }

    return(
            <div className="form-group">
                    <input id="form_name1" 
                        className="form-control" 
                        type="text" 
                        name="test"
                        required 
                        onSelect={()=>console.log("afa")} 
                        ref={inputRef} 
                        value={value} 
                        placeholder={placeholder} 
                        onFocus={handleDropDownIn} 
                        onInput={(e) => {setValue(e.target.value); createAutocomplete(e.target.value, props.data)}} 
                        onBlur={handleDropDownOut}
                    ></input>
                <label for="form_name1">{props.field}<span className="gl-form-asterisk"></span></label>
                            
                <FontAwesomeIcon icon={faCaretDown} className="dropdown-caret"/>

            {
                (dropdownItems && !autocomplete && dropdownData) &&
                <div className="dropdown-content">
                    <span className="item-choice" onClick={(e) => handleItemSelect(e, "*")}>*</span>
                    {
                    props.data.map((item) => {
                        return (
                            <span key={item.id} className="item-choice" onClick={(e) => handleItemSelect(e, item[props.field])}>{item[props.field]}</span>
                        )
                    })
                }</div>
            }
            {
                (dropdownItems && autocomplete && dropdownData) &&
                <div className="dropdown-content">
                    <span className="item-choice" onClick={(e) => handleItemSelect(e, "*")}>*</span>
                    {
                    
                    autocomplete.map((item) => {
                        return (
                            <span key={item.id} className="item-choice" onClick={(e) => handleItemSelect(e, item[props.field])}>{item[props.field]}</span>
                        )})

                    }

                </div>
            }

                        
            

        </div>

    )
    }

export default DropdownItem;
