import './MapView.css'
import React, { useEffect, useRef, useMemo } from 'react';
import { render } from 'react-dom';
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl
} from 'react-map-gl';

import {getData} from './LocalStorageFunctions'
import Pin from './Pin';
import { faX, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from "react-router-dom"
import { useState } from 'react';
import BatteryPercentage from './BatteryPercentage';


const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ3JpZGxvY2siLCJhIjoiY2xpdDBnYnN2MWFkaDNlazk4dTB2cTYzbSJ9._b1985hTllF85zKwvrHYCA'; // Set your Mapbox token here

export default function MapView(props) {
  console.log(props)
  const navigate = useNavigate()
  const mapRef = useRef(null)
  const {locationId} = useParams();
  const [mapSideDrawer, setMapSideDrawer] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [mapStyle, setMapStyle] = useState((props.view === "map") ? "mapbox://styles/mapbox/streets-v12" : "mapbox://styles/mapbox/streets-v12")
  const [viewport, setViewport] = useState((props.view === "map") ? {
      latitude: 42.3492825,
      longitude: -71.09559300000001,
      zoom: 12,
      bearing: 0,
      pitch: 25
    } : props.view)

  useEffect(() => {
    setViewport(prevViewport => ({
      ...prevViewport,
      latitude: props.view.latitude,
      longitude: props.view.longitude
    }));
  }, [props.view.latitude, props.view.longitude]);

  useEffect(() => {

    if (locationId) {
      console.log(locationId)
      const geoData = getData('geoJSON')
      let latitude;
      let longitude;
      geoData.forEach(element => {
        if (element.locationId === locationId) {
          latitude = element.latitude;
          longitude = element.longitude;
        }
        return
      });
      handleLocationSelection(locationId)
      setViewport({ //doesnt work?
        latitude: latitude,
        longitude: longitude,
        zoom: 16,
        bearing: 0,
        pitch: 25
      })
    }
  }, []);

  const locations = useMemo(() => getData('geoJSON'), []);
  const pins = useMemo(() =>
        locations.map((location, index) => (
          <Marker
            key={`marker-${index}`}
            longitude={location.longitude}
            latitude={location.latitude}
            anchor="bottom"
            onClick={(e) => markerClick(e, location)}
          >
            <Pin unitCount={location.count}/>
          </Marker>
        )),
      []
    );
  const toggleOrder = (type) => {
    console.log(type)
    //based on the type, sort the selectedLocation array
    //setSelectedLocation(sortedArray)

    switch (type) {
      case "name":
        //beak up by the space
        const sortedArray = selectedLocation.sort((a, b) => {
          const aName = a.name.split(" ")[1]
          const bName = b.name.split(" ")[1]
          return aName - bName
        })
        console.log(sortedArray)
        setSelectedLocation(sortedArray)
        break;
      case "status":
        const sortedArray2 = selectedLocation.sort((a, b) => {
          const aStatus = a.status
          const bStatus = b.status
          return aStatus - bStatus
        })
        console.log(sortedArray2)
        setSelectedLocation(sortedArray2)
        break;
      case "lockBattery":
        const sortedArray3 = selectedLocation.sort((a, b) => {
          const aBattery = a.lockBattery
          const bBattery = b.lockBattery
          return aBattery - bBattery
        })
        console.log(sortedArray3)
        setSelectedLocation(sortedArray3)
        break;
      case "size":
        const sortedArray4 = selectedLocation.sort((a, b) => {
          const aSize = a.size
          const bSize = b.size
          return aSize - bSize
        }
        )
        setSelectedLocation(sortedArray4)
        break;
      default:
        break;
  }
  }

  const markerClick = (e, location) => {
    e.originalEvent.stopPropagation();
    mapRef.current.easeTo({
                center: [location.longitude, location.latitude],
                zoom: 14,
                duration: 500,
              });
    
    handleLocationSelection(location.locationId)
    
  };

  const handleLocationSelection = (locationId) => {
    const units = getData('units');
    const selectedUnits = units.filter((unit) => unit.locationId === locationId);
    console.log(selectedUnits)
    setMapSideDrawer(true)
    setSelectedLocation(selectedUnits)
    navigate(`/map/${locationId}`)
  }

  return (
      <div className={"map-view " + (props.view === 'map' ? 'map-height':"") }>
        <Map
          initialViewState={viewport}
          mapStyle={mapStyle}
          mapboxAccessToken={MAPBOX_TOKEN}
          key={`${props.view.latitude}-${props.view.longitude}`} // Add the key attribute
          onViewportChange={newViewport => setViewport(newViewport)}
          ref={mapRef}
        >
          <NavigationControl position="top-left" />
          <ScaleControl />

          {pins}

      
        </Map>

        {
          mapSideDrawer && selectedLocation &&
          <div className="map-side-drawer">
              <div className="map-side-drawer-header">
                <section>
                  <h1>{selectedLocation[0].facility}</h1>
                  <FontAwesomeIcon icon={faX} className="map-side-drawer-x" onClick={() => setMapSideDrawer(false)}/>
                </section>
                <section>
                  <span>
                    <h3>{selectedLocation[0].building}</h3>
                    <h4>{selectedLocation[0].address}, {selectedLocation[0].city}, {selectedLocation[0].country}</h4>
                  </span>
                  <button>View Facility</button>
                </section>
                <h2>{selectedLocation.length} Units</h2>
              </div>

              <div className="map-side-drawer-unit">
                      <table>
                        <thead>
                          <tr>
                            <th onClick={() => toggleOrder('name')}>Unit Number
                              <FontAwesomeIcon icon={faArrowDown} className="map-side-drawer-arrow"/>
                            </th>
                            <th onClick={() => toggleOrder('status')}>Status
                              <FontAwesomeIcon icon={faArrowDown} className="map-side-drawer-arrow"/>
                            </th>
                            <th onClick={() => toggleOrder('lockBattery')}>Lock Battery
                              <FontAwesomeIcon icon={faArrowDown} className="map-side-drawer-arrow"/>
                            </th>
                            <th onClick={() => toggleOrder('size')}>Size
                              <FontAwesomeIcon icon={faArrowDown} className="map-side-drawer-arrow"/>
                            </th>
                            
                          </tr>
                        </thead>
                        <tbody>
                        {
                          selectedLocation.map((unit) => {
                            return(
                          <tr 
                            key={unit.id} 
                            className="data-row"
                            onClick={() => navigate(`/locks/unit/${unit.id}`)}

                          >
                            <td>{unit.name}</td>
                            {
                              (unit.status) === "available" ?
                              <td>
                                <span className="available">
                                  {unit.status}
                                </span>
                              </td>
                              :
                              <td>
                                <span className="not-available">
                                  {unit.status}
                                </span>
                              </td>
                            }
                            <td><BatteryPercentage battery={unit.lockBattery}></BatteryPercentage></td>
                            <td>{unit.size}</td>
                            
                          </tr>
                            )
                          })
                        }
                        </tbody>

                      </table>
              </div>
          </div>
        }
      </div>

  );
}

export function renderToDom(container) {
  render(<MapView/>, container);
}
