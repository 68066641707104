import './LoginPage.css'
import { useState } from 'react'
import HexagonBackground from '../Components/HexagonBackground'
import logo from '../Img/logo1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faArrowLeft, faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import googleLogo from '../Img/google.png'
import { fetchSignInMethodsForEmail, createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, addUserOrg, checkUser } from '../FirebaseFunctions'
import Loader from '../Components/Loader'

const SignUpPage = (props) => {
    let navigate = useNavigate()
    const [googleSignIn, setGoogleSignIn] = useState(props.google ? true : false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [loader, setLoader] = useState(false)
    const [signupObject, setSignupObject] = useState({
        company: null,
        orgId: null,
        name: null,
        email: null,
        password: null,
        passwordConfirm: null,
        phoneNumber: null,
        orgSettings: [],
    })

    const signUpValidator = () => {
        if (!signupObject.company || !signupObject.name || !signupObject.email || !signupObject.password || !signupObject.passwordConfirm) {
            return "Please fill out all fields"
        }
        if (signupObject.password !== signupObject.passwordConfirm) {
            return "Passwords do not match"
        }
        //check if email is valid
        if (!signupObject.email.includes("@")) {
            return "Invalid email"
        }
        //check if phone number is valid
        if ((signupObject.phoneNumber) && (signupObject.phoneNumber.length !== 9)) {
            return "Invalid phone number"
        }
        //use firebase to check if email is already in use
        fetchSignInMethodsForEmail(auth, signupObject.email)
            .then((signInMethods) => {
                if (signInMethods.length > 0) {
                    return "Email already in use"
                }
            })
            .catch((error) => {
                console.log(error)
                return "Error"
            })

        return true
    }
    const googleSignUpValidator = () => {
        if (!signupObject.company || !signupObject.name) {
            return "Please fill out your company name and full name"
        }
        //check if phone number is valid
        if ((signupObject.phoneNumber) && (signupObject.phoneNumber.length !== 9)) {
            return "Invalid phone number"
        }
        return true
    }

    const strip = (str) => {
        //remove "Firebase:"
        return str.substring(9)
    }

    const handleSignUp = async (e) => {
        e.preventDefault()
        setLoader(true)
        console.log(signupObject)
        const validation = signUpValidator()
        if (validation === true) {
            console.log("valid")
            //do the firebase stuff
            await createUserWithEmailAndPassword(auth, signupObject.email, signupObject.password)
                .then((userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    console.log(user)

                    //update the user's display name
                    updateProfile(auth.currentUser, {
                        displayName: signupObject.name,
                        phoneNumber: signupObject.phoneNumber
                    }).then(() => {
                        // Profile updated!
                        console.log("profile updated")
                        //add all this info to the database
                        addUserOrg(user.uid, signupObject.company, signupObject.name, signupObject.phoneNumber, user.email)
                        setLoader(false)
                        navigate('/home')
                        // ...
                    }).catch((error) => {
                        // An error occurred
                        console.log(error)
                        // ...
                    });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage)
                    setLoader(false)
                    setErrorMessage(strip(errorMessage))
                    // ..
                });

        }
        else {
            setErrorMessage(validation)
        }
    }

    const handleGoogleSignup = async (e) => {
        e.preventDefault()
        setLoader(true)
        const validation = googleSignUpValidator()
        if (validation === true) {
            try {
                const provider = new GoogleAuthProvider();
                const userCredential = await signInWithPopup(auth, provider);
                const user = userCredential.user;
                //start loader and add to database 
    
                //take the company, name and phone if exists
                //check if user already exists, uid exists

                const check = await checkUser(user.uid)
                if (check) {
                    setLoader(false)
                    return setErrorMessage("User already exists")
                }
                else {
                    await addUserOrg(user.uid, signupObject.company, signupObject.name, signupObject.phoneNumber, user.email)
                    console.log(user)
                    setLoader(false)
                    navigate('/home/welcome')
                }

                
            }
            catch (error) {
                setLoader(false)
                setErrorMessage("Something went wrong. Please try again.")
                console.log(error)
            }
        }
        else {
            setErrorMessage(validation)
            setLoader(false)
        }

    }
    const handleSignupInput = (e) => {
        console.log(e.target.name, e.target.value)
        setSignupObject({
            ...signupObject,
            [e.target.name]: e.target.value,
        });

    }

    return (
        <div className="login-background">
            <HexagonBackground />
            <div className="login-container">
                <img src={logo} className="login-logo"></img>
                {
                    !errorMessage ? (
                        <p>Get Started with Gridlock Today!</p>
                    ):(
                        <div className="error-message">
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            <p>{errorMessage}</p>
                        </div>
                        
                    )
                }
                
                {
                    loader ? (
                        <Loader></Loader>
                    ):(
                        <form>
                            <div className="form-group">
                                <input id="company-login" 
                                    className="form-control" 
                                    type="text" 
                                    value={signupObject.company}
                                    name="company"
                                    onChange={(e) => handleSignupInput(e)}
                                    required 

                                ></input>
                                <label for="company-login">Company*</label>
                            </div>
                            <div className="form-group">
                                <input id="company" 
                                    className="form-control" 
                                    type="text" 
                                    value={signupObject.name}
                                    name="name"
                                    onChange={(e) => handleSignupInput(e)}
                                    required 

                                ></input>
                                <label for="name">Full Name*</label>
                            </div>
                            {   !googleSignIn &&
                                <>
                                    <div className="form-group">
                                        <input id="form_name1" 
                                            className="form-control" 
                                            type="text" 
                                            value={signupObject.email}
                                            onChange={(e) => handleSignupInput(e)}
                                            name="email"
                                            required 
                                        ></input>
                                        <label for="form_name1">Email*</label>
                                    </div>
                                    <div className="form-group">
                                        <input id="email" 
                                            className="form-control" 
                                            type="password" 
                                            name="password"
                                            onChange={(e) => handleSignupInput(e)}
                                            required 
                                            value={signupObject.password}


                                        ></input>
                                        <label for="email">Password*</label>
                                    </div>
                                    <div className="form-group">
                                        <input id="passwordConfirm" 
                                            className="form-control" 
                                            type="password" 
                                            name="passwordConfirm"
                                            onChange={(e) => handleSignupInput(e)}
                                            required
                                            value={signupObject.passwordConfirm}
                                        ></input>
                                        <label for="passwordConfirm">Confirm Password*</label>
                                    </div>
                                </>
                            }

                            <div className="form-group">
                                <input id="phone_number" 
                                    className="form-control" 
                                    type="tel" 
                                    name="phoneNumber"
                                    onChange={(e) => handleSignupInput(e)}
                                    required 
                                    value={signupObject.phoneNumber}

                                ></input>
                                <label for="phone_number">Phone Number</label>
                            </div>
                            {
                                !googleSignIn ? (
                                <div className="signup-buttons">
                                    <button
                                        className="google-option"
                                        onClick={(e) => {setGoogleSignIn(!googleSignIn); e.preventDefault();setErrorMessage(false)}}
                                    >
                                    <img src={googleLogo}></img>
                                    Sign up with Google</button>
                                    <button
                                        onClick={(e) => handleSignUp(e)}
                                        >Sign Up
                                        <FontAwesomeIcon icon={faArrowRight}/>
                                    </button>
                                    
                                </div>
                                )
                                :
                                (
                                <div className="signup-buttons">
                                    <button
                                        onClick={(e) => {setGoogleSignIn(!googleSignIn); e.preventDefault();setErrorMessage(false)}}
                                    >   <FontAwesomeIcon icon={faArrowLeft}/>
                                        Go Back</button>
                                    <button
                                        className="google-option"
                                        onClick={(e) => handleGoogleSignup(e)}
                                    >
                                    <img src={googleLogo}></img>
                                    Sign up with Google
                                    <FontAwesomeIcon icon={faArrowCircleRight}/>
                                    </button>
                                </div>
                                )
                            }
                            <a onClick={() => navigate('/login')}>Already have an account? Sign in</a>
                            
                        </form>
                    )
                }
            </div>
        </div>
    )
}

export default SignUpPage