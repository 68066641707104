import './LoginPage.css'
import { useState } from 'react'
import HexagonBackground from '../Components/HexagonBackground'
import logo from '../Img/logo1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import googleLogo from '../Img/google.png'
import { UserAuth } from '../Auth/AuthContext'
import Loader from '../Components/Loader'
import { checkUser } from '../FirebaseFunctions'

const LoginPage = () => {
    let navigate = useNavigate()
    const { signIn, googleSignIn } = UserAuth();
    const [loader, setLoader] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [loginObject, setLoginObject] = useState({
        email: null,
        password: null
    })
    
    const handleLogin = async (e) => {
        e.preventDefault()
        console.log(loginObject)
        try {
            const userCredential = await signIn(loginObject.email, loginObject.password);
            const user = userCredential.user;
            console.log(user)
            navigate('/home')
        }
        catch (error) {
            setErrorMessage("Invalid email or password")
        }
    }
    
    const handleGoogleLogin = async (e) => {
        e.preventDefault()
        try {
            setLoader(true)
            const response = await googleSignIn()
            setLoader(false)
            console.log(response)
            const user = await checkUser(response.user.uid)
            console.log(user)
            if (user) {
                navigate('/home')
            }
            else {
                navigate('/signup-google')
            }
    
        }
        catch (error) {
            setErrorMessage("Something went wrong. Please try again.")
        }
    }

    const handleLoginInput = (e) => {
        setLoginObject({
            ...loginObject,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <div className="login-background">
            <HexagonBackground />
            <div className="login-container">
                <img src={logo} className="login-logo"></img>
                {
                    errorMessage && (
                        <div className="error-message">
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            <p>{errorMessage}</p>
                        </div>
                    )
                }
                {
                    loader ? (
                        <Loader></Loader>
                    ):(
                        <form>
                            <div className="form-group">
                                <input id="email" 
                                    className="form-control" 
                                    type="text" 
                                    name="email"
                                    value={loginObject.email}
                                    onChange={(e) => handleLoginInput(e)}
                                    required 
                                ></input>
                                <label for="email">Email</label>
                            </div>
                            <div className="form-group">
                                <input id="password" 
                                    className="form-control" 
                                    type="password" 
                                    name="password"
                                    required 
                                    value={loginObject.password}
                                    onChange={(e) => handleLoginInput(e)}

                                ></input>
                                <label for="password">Password</label>
                            </div>
                            <button
                                onClick={(e) => handleLogin(e)}
                                >Login
                                <FontAwesomeIcon icon={faArrowRight}/>
                            </button>
                            <button 
                                onClick={(e) => handleGoogleLogin(e)}
                                className="google-option"
                            ><img src={googleLogo}></img>Sign in with Google</button>
                            <a onClick={() => navigate('/signup')}>New to Gridlock? Create an account</a>
                            
                        </form>
                    )
                }
            </div>
        </div>
    )
}

export default LoginPage