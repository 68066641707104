import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import './FacetSearchItem.css'

function FacetSearchItem(props) {
    const [facetItems, setFacetItems] = useState(false)
    //do an elastic search query for the topic
    //this is needed especially for costly searches like units or customer
    const facetData = [
        {
            item: "item1",
            count: 1
        },
        {
            item: "item2",
            count: 2
        },
        {
            item: "item3",
            count: 3
        },
        {
            item: "item4",
            count: 4
        },
        {
            item: "item5",
            count: 5
        },
        {
            item: "item6",
            count: 6
        },
        {
            item: "item7",
            count: 7
        },
        {
            item: "item8",
            count: 8
        },
        {
            item: "item9",
            count: 9
        },
        {
            item: "item10",
            count: 10
        },
    ]
    const handleFacetClick = () => {
        //if facetItems is false, set it to the facetData
        //if facetItems is true, set it to false
        if (facetItems === false) {
            setFacetItems(facetData)
        } else {
            setFacetItems(false)
        }

    }
    return(
        <div className="facet-item" >
            <span onClick={() => handleFacetClick()}>
                {
                    facetItems ? <FontAwesomeIcon icon={faAngleDown} className="facet-caret"/> : <FontAwesomeIcon icon={faAngleRight} className="facet-caret"/>
                }
                <h3>{props.topic}</h3>
            </span>
            

            {
                facetItems &&
                <div className="facet-content">
                    {
                        facetItems.map((item) => {
                            return (
                                <div className="facet-choice" key={item.item}>
                                    <input type="checkbox" defaultChecked></input>
                                    <p>{item.item}</p>
                                </div>)
                        }
                        )
                    }
                </div>
            }
        </div>

    )
    }

export default FacetSearchItem;