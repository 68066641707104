import './AdminPageHeader.css'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome, faCube,faCaretDown, faLock, faUsers, faUser, faList, faLocationPinLock, faCog, faKey} from '@fortawesome/free-solid-svg-icons'
import {faFortAwesome} from '@fortawesome/free-brands-svg-icons'
import { useEffect, useState } from "react"
import DropdownItem from './DropdownItem'

function AdminPageHeader(props) {
    const [time, setTime] = useState({icon: "1h", text: "Past Hour"})
    const [selectTime, setSelectTime] = useState(false)
    let icon;
    switch(props.page){
        case "Locks":
            icon = faLock;
            break;
        case "Users":
            icon = faUsers;
            break;
        case "Account":
            icon = faUser;
            break;
        case "Activity":
            icon = faList;
            break;
        case "Pins":
            icon = faKey;
            break;
        case "Map":
            icon = faLocationPinLock;
            break;
        case "Settings":
            icon = faCog;
            break;
        case "Home":
            icon = faHome;
            break;
        default:
            icon = faCube;
    }

    const handleGridList = (e, type) => {
        const parent = e.target.parentElement
        const buttons = parent.children
        for (let i = 0; i < buttons.length; i++){
            buttons[i].classList.remove("grid-list-selected")
        }

        //add .grid-list-selected to the clicked button
        e.target.classList.add("grid-list-selected")
        props.toggleView(type)

    }

    /* make the unit data here dynamic
    {
        id: 1,
        field: "name",
    }
    */

    const selectNewTime = (e, icon, text) => {
        props.triggerTime(icon, text)
        setTime({icon: icon, text: text})
        setSelectTime(false)
    }


    return(
        <div className="admin-page-header">
            <section>
                <div className="admin-page-header-section">
                    <FontAwesomeIcon icon={icon} />
                    <h1>{props.page}</h1>
                </div>
                <div className="admin-page-header-section">
                    {
                        ((props.page=='Activity') || (props.page=="Home") &&(
                        <div className={'time-container select-' + selectTime} >
                            <div className="time-display" onClick={() => {selectTime ? setSelectTime(false):setSelectTime(true)}}>
                                <span className={time.icon === "Live" ? "live blinking":""}>{time.icon}</span>
                                <p>{time.text}</p>
                                {
                                    !selectTime && (
                                        <FontAwesomeIcon className="time-caret" icon={faCaretDown}></FontAwesomeIcon>
                                    )
                                }
                                
                            </div>
                            {
                                selectTime && (
                                    <div className="time-options">
                                        <div className="time-option" onClick={(e) => selectNewTime(e, 'Live', 'Realtime Data')}>
                                            <span className="live">Live</span>
                                            <p>Realtime Data</p>
                                        </div>
                                        <div className="time-option" onClick={(e) => selectNewTime(e, '30m', 'Past 30 minutes')}>
                                            <span>30m</span>
                                            <p>Past 30 minutes</p>
                                        </div>
                                        <div className="time-option" onClick={(e) => selectNewTime(e, '1h', 'Past hour')}>
                                            <span>1h</span>
                                            <p>Past hour</p>
                                        </div>
                                        <div className="time-option" onClick={(e) => selectNewTime(e, '4h', 'Past 4 hours')}>
                                            <span>4h</span>
                                            <p>Past 4 hours</p>
                                        </div>
                                        <div className="time-option" onClick={(e) => selectNewTime(e, '1d', 'Past Day')}>
                                            <span>1d</span>
                                            <p>Past Day</p>
                                        </div>
                                        <div className="time-option" onClick={(e) => selectNewTime(e, '2d', 'Past 2 Days')}>
                                            <span>2d</span>
                                            <p>Past 2 Days</p>
                                        </div>
                                        <div className="time-option" onClick={(e) => selectNewTime(e, '4d', 'Past 4 Days')}>
                                            <span>4d</span>
                                            <p>Past 4 Days</p>
                                        </div>
                                        <div className="time-option" onClick={(e) => selectNewTime(e, '1w', 'Past Week')}>
                                            <span>1w</span>
                                            <p>Past Week</p>
                                        </div>
                                        <div className="time-option" onClick={(e) => selectNewTime(e, '1mo', 'Past Month')}>
                                            <span>1mo</span>
                                            <p>Past Month</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        )
                        )
                    }
                </div>

                

            </section>
            {
                (props.page === "Locks") && (
                    <div className="lock-bar-search">
                        <div className="filter-container grid-list">
                            {
                                (props.lockView === "grid") ? (
                                    <>
                                    <button id="grid" className="grid-list-selected" onClick={(e) => handleGridList(e, "grid")}>Grid</button>
                                    <button id="list" onClick={(e) => handleGridList(e, "list")}>List</button>  
                                    </>
                                ) : (
                                    <>
                                    <button id="grid" onClick={(e) => handleGridList(e, "grid")}>Grid</button>
                                    <button id="list" className="grid-list-selected" onClick={(e) => handleGridList(e, "list")}>List</button>
                                    </>
                                )

                            }
                        </div>
                        <DropdownItem data={props.units} field={'name'} bubbleUpSelection={(data) => props.triggerSelection(data)}/>
                        <DropdownItem data={props.units} field={'facility'} bubbleUpSelection={(data) => props.triggerSelection(data)}/>
                        <DropdownItem data={props.units} field={'building'} bubbleUpSelection={(data) => props.triggerSelection(data)}/>

                        {
                            /*
                        <div className="filter-container fill-by">
                            <label>Color By</label>
                            <DropdownItem data={['Availability', 'Battery', "Lock Status"]}></DropdownItem>
                        </div>
                        */
                        }

                        

                    </div>
                )
            }
            
        </div>

    )
}

export default AdminPageHeader