import * as React from 'react';
import "./Pin.css"
import mapLogo from '../Img/maplogo.png'

function Pin(props) {
  console.log(props)
  return (
      <span className={"pin-object " + ((props.unitCount === 1) ? "single-lock":"")} >
        {(props.unitCount > 1) ? props.unitCount : ""}
        <img src={mapLogo} alt="map logo" className="map-logo"></img>
        <div className="bottom-arrow"></div>
      </span>
  );
}

export default React.memo(Pin);