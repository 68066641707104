import { Route, Navigate } from "react-router-dom";
import { auth } from "../FirebaseFunctions";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";


const BaseURLRoute = ({ children }) => {
    const [user, setUser] = useState(null);
    useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
        setUser(currentUser);
        }
        else {
        setUser(false);
        }
    }
    )
    }
    , []);
    
    
    return (user ? <Navigate to="/home"></Navigate> : <Navigate to="/login"></Navigate>);
}

export default BaseURLRoute;
