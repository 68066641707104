import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPinLock, faUserGroup, faLineChart, faGears, faLock, faArrowLeftLong, faArrowRight, faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import './Welcome.css'
import { updateOrgSettings } from '../FirebaseFunctions'

const Welcome = (props) => {//orgId and company
    console.log(props)
    const REACT_APP_ORG_API = process.env.REACT_APP_ORG_MANAGEMENT_API
    const [activeTab, setActiveTab] = useState('weclomeLocations')
    const [buttonLabel, setButtonLabel] = useState("Next")
    const contentSchema = [
        {
            view: "weclomeLocations",
            header: "Locations",
            content: "Manage your locations and units here",
            icon: faLocationPinLock,
            inputs: [
                {   type: "number",
                    key: "locations",
                    label: "Number of Storage Locations",
                    default: 4,
                    description: "How many storage facilities do you want within your demo?",
                    min: 1,
                    max: 10
                },

            ]
        },
        {
            view: "weclomeUnits",
            header: "Units",
            content: "Manage your units here",
            icon: faLock,
            inputs: [
                {   type: "number",
                    key: "unitsPerLocation",
                    label: "Number of Units per Location",
                    default: 50,
                    description: "How many units do you want per location?",
                    min: 1,
                    max: 100
                    
                },
                {   type: "number",
                    key: "gridlocksPerLocation",
                    label: "Number of Gridlocks per Location",
                    default: 10,
                    description: "How many gridlocks do you want per location?",
                    min: 1,
                    max: 100
                },
                {   type: "number",
                    key: "pinsPerLock",
                    label: "Average Number of Pins on each lock",
                    default: 2,
                    description: "How many pins do you want per lock?",
                    min: 1,
                    max: 10
                },
                {   type: "number",
                    key: "availablePercentage",
                    label: "Percentage of Available Units",
                    default: 0.5,
                    description: "What percentage of units do you want to be available? Remaining will be occupied.",
                    min: 0,
                    max: 1
                }
            ]
        },
        {
            view: "weclomeCustomers",
            header: "Customers",
            content: "Manage your customers here",
            icon: faUserGroup,
            inputs: [
                {   type: "number",
                    key: "customers",
                    label: "Number of Customers",
                    default: 50,
                    description: "How many customers do you want in your demo?",
                    min: 1,
                    max: 100
                },
            ]
        },
        {
            view: "weclomeActivity",
            header: "Activity",
            content: "View your activity here",
            icon: faLineChart,
            inputs: [
                {   type: "number",
                    key: "batteryGranularity",
                    label: "Battery Granularity",
                    default: 60,
                    description: "How often do you want to recieve lock battery updates? (in seconds)",
                    min: 5,
                    max: 60
                },
                {   type: "number",
                    key: "activityGranularity",
                    label: "Activity Granularity",
                    default: 60,
                    description: "How often do you want to activities to be ingested? (in seconds)",
                    min: 5,
                    max: 60
                }
            ]
        },
        {
            view: "welcomeCustom",
            header: "Custom",
            content: "Customize your dashboard here",
            icon: faGears,
            inputs: [
                {   type: "select",
                    key: "dashboardColors",
                    label: "Customize your dashboard colors",
                    default: "current",
                    description: "Change your dashboard colors",
                },
                {
                    type: "checkbox",
                    key: "darkMode",
                    label: "Dark Mode",
                    default: "light",
                    description: "Change to Dark Mode"
                }
            ]
        }
    ]
    const [demoData, setDemoData] = useState({
        locations: contentSchema[0].inputs[0].default,
        unitsPerLocation: contentSchema[1].inputs[0].default,
        gridlocksPerLocation: contentSchema[1].inputs[1].default,
        pinsPerLock: contentSchema[1].inputs[2].default,
        availablePercentage: contentSchema[1].inputs[3].default,
        customers: contentSchema[2].inputs[0].default,
        batteryGranularity: contentSchema[3].inputs[0].default,
        activityGranularity: contentSchema[3].inputs[1].default,
        dashboardColors: contentSchema[4].inputs[0].default,
        darkMode: contentSchema[4].inputs[1].default
    })

    const handleDemoDataInput = (e) => {
        const key = e.target.name
        var value = e.target.value

        if ((key !== "dashboardColors") || (key !== "darkMode")){
            //values must be numbers
            value = parseInt(value)
        }
        console.log(key, value)
        setDemoData({...demoData, [key]: value})
    }

    const handleActiveTab = (e) => {
        var tab = e.target.getAttribute("name")
        //if tab.target is section, then get the parent
        if (!tab) {
            //recursively get the parent until you get the name
            while (!tab){
                e.target = e.target.parentElement
                tab = e.target.getAttribute("name")
            }
            const parent = e.target.parentElement
            const buttons = parent.children
            for (let i = 0; i < buttons.length; i++){
                buttons[i].classList.remove("welcome-popup-active")
            }
            e.target.classList.add("welcome-popup-active")
            setActiveTab(tab)

            if (tab === "welcomeCustom"){
                setButtonLabel("Finish")
            }
            else {
                setButtonLabel("Next")
            }
        }
        if (tab) {
            const parent = e.target.parentElement
            const buttons = parent.children
            for (let i = 0; i < buttons.length; i++){
                buttons[i].classList.remove("welcome-popup-active")
            }
            e.target.classList.add("welcome-popup-active")
            setActiveTab(tab)

            if (tab === "welcomeCustom"){
                setButtonLabel("Finish")
            }
            else {
                setButtonLabel("Next")
            }
        }
    }

    const nextTab = () => {
        const currentTab = contentSchema.find((item) => item.view === activeTab)
        const currentIndex = contentSchema.indexOf(currentTab)
        const nextIndex = currentIndex + 1
        const nextTab = contentSchema[nextIndex]


        if (nextIndex === contentSchema.length - 1) {
            setButtonLabel("Finish")
        }
        if (nextIndex === contentSchema.length) {
            handleSubmit()
            return
        }

        const tabs = document.getElementsByClassName("welcome-popup-active")
        const tabParent = tabs[0].parentElement
        const tabButtons = tabParent.children
        for (let i = 0; i < tabButtons.length; i++){
            tabButtons[i].classList.remove("welcome-popup-active")
        }
        const nextTabButton = document.querySelector(`[name="${nextTab.view}"]`)
        nextTabButton.classList.add("welcome-popup-active")
        setActiveTab(nextTab.view)
    } 

    const handleSubmit = async () => {
        console.log(demoData)
        //upload to firestore database
        await updateOrgSettings(props.orgId, demoData)
        //add props.orgId to the demoData
        demoData.orgId = props.orgId

        await fetch(REACT_APP_ORG_API + "/api/v1/addOrganization", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + "", //add the token here
            },
            body: JSON.stringify(demoData)
        })
        .then((res) => res.json())
        .then((data) => {
            console.log(data)
        })
        //all APIs to trigger a demo environment
        //trigger the cronjob

        //apply the settings to the local data

        
    }


    return (
    <div className="container welcome-container">
        <div className="welcome-popup-container">
          <div className="welcome-popup-side-tabs">
            {
                contentSchema.map((item, i) => {
                    return (
                        <div className={i === 0 ? "welcome-popup-active" : ""}
                                name={item.view}
                                onClick={(e) => handleActiveTab(e)}
                                key={i}
                                >
                            <section style={{"display": "flex"}}>
                                <FontAwesomeIcon icon={item.icon} />
                                {item.header}
                            </section>
                            <FontAwesomeIcon className="popup-arrow" icon={faArrowRight} />            
                        </div>
                    )
                })
            }
          </div>
          <div className="welcome-popup-content">
            <h2>{contentSchema.find((item) => item.view === activeTab).header}</h2>
            <h3>{contentSchema.find((item) => item.view === activeTab).content}
            </h3>
            <div className="welcome-popup-inputs">
                {
                    contentSchema.find((item) => item.view === activeTab).inputs.map((input, i) => {
                        return (
                            <div className="welcome-popup-input" key={i}>
                                <span>
                                    <label>{input.label}</label>
                                    <h6>
                                        {input.description}</h6>
                                </span>
                                
                                <span className="input">
                                    <input type={input.type} 
                                            name={input.key} 
                                            value={demoData[input.key]} 
                                            onChange={(e) => handleDemoDataInput(e)}></input>
                                    <p>Default is {input.default}</p>
                                </span>

                            </div>
                        )
                    })
                }
            </div>
            <button className="input-next-button" onClick={() => nextTab()}>{buttonLabel}</button>
          </div>
        </div>
      </div>
    )
}

export default Welcome