import './UnitDetails.css'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserCheck, faUserLargeSlash,faPen, faCaretDown, faLock, faLockOpen, faKey, faUser, faBuilding, faLevelUp, faLocationDot, faBox, faMoneyBill, faDollarSign, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { useState } from "react"
import MapView from './MapView'
import BatteryPercentage from './BatteryPercentage'
import { useNavigate } from 'react-router-dom'

function UnitDetails(props) {
    console.log(props)
    const navigate = useNavigate()
    const windowPage = window.location.pathname.includes("locks") ? true : false
    const [unitDetailsTab, setUnitDetailsTab] = useState("lock")
    //const [unitDetails, setUnitDetails] = useState(props.unitData)
    
    const unitDetailsSelection = (e, tab) => {
        //do the e target class change thing here
        setUnitDetailsTab(tab)

        const parent = e.target.parentElement
        const buttons = parent.children
        for (let i = 0; i < buttons.length; i++){
            buttons[i].classList.remove("unit-details-selected")
        }
        e.target.classList.add("unit-details-selected")

    }
    
    
    
    return (
        <div className="unit-details">
        
        <div className="unit-details-header" style={{display: windowPage?'flex':'block'}}>
            <h2>
                {props.unitData.name}
                <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
            </h2>
            <section className="header-pills">
                <span>
                    <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
                    5 Pins
                    <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                </span>
                
                <BatteryPercentage battery={props.unitData.lockBattery}></BatteryPercentage>

                <span className={props.unitData.status === "available" ? "available":"not-available"}>
                    {props.unitData.status === "available" ? <FontAwesomeIcon icon={faUserCheck}></FontAwesomeIcon> : <FontAwesomeIcon icon={faUserLargeSlash}></FontAwesomeIcon>}
                    {(props.unitData.status === "available") ? "Assigned" : "Unassigned"}
                    <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                </span>
                <span className={props.unitData.gridlock ? "gridlocked" : "not-gridlocked"}>
                    {props.unitData.gridlock ? <FontAwesomeIcon icon={faLock}></FontAwesomeIcon> : <FontAwesomeIcon icon={faLockOpen}></FontAwesomeIcon>}
                    {(props.unitData.gridlock == true) ? "Locked" : "Unlocked"}  
                    <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                </span>
            </section>
            
        </div>
        <nav>
            <span className="unit-details-selected" onClick={(e) => unitDetailsSelection(e, 'lock')}>Lock</span>
            <span onClick={(e) => unitDetailsSelection(e, 'activity')}>Activity</span>
        </nav>
        {
            unitDetailsTab === "lock" && (
                <div className="unit-details-container">
                    <div className="unit-details-section">
                        <section className="unit-details-pills">
                            <h3>
                                Location
                            </h3>

                            <span>
                                <FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon>
                                {props.unitData.facility}
                            </span>
                            <span>
                                <FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon>
                                {props.unitData.building}</span>
                            <span>
                                <FontAwesomeIcon icon={faLevelUp}></FontAwesomeIcon>
                                Floor {props.unitData.floor}</span>
                            <span>
                                <FontAwesomeIcon icon={faLocationDot}></FontAwesomeIcon>
                                {props.unitData.address}</span>
                            <span>
                                <FontAwesomeIcon icon={faLocationDot}></FontAwesomeIcon>
                                {props.unitData.city}, {props.unitData.state}, {props.unitData.zip}, {props.unitData.country}</span>
                            <span>
                                <FontAwesomeIcon icon={faBox}></FontAwesomeIcon>
                                {props.unitData.size} sqft</span>
                            <span>
                                <FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>
                                {props.unitData.price}24/month</span>
                        </section>
                        <section className="unit-details-pills">
                            <h3>Current User
                                <button>View Previous Users</button>
                            </h3>
                            <span>
                                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                                {props.unitData.user.fName}</span>
                            <span>{props.unitData.user.lName}</span>
                            <span>{props.unitData.user.email}</span>
                            <span>{props.unitData.user.phoneNumber}</span>
                            <span>{props.unitData.start}</span>
                        </section>
                        
                    </div>
                    <div className="unit-details-map">
                        <button 
                            className="absolute-view-button"
                            onClick={() => navigate(`/map/${props.unitData.locationId}`)}
                            >View in Map View</button>
                        <MapView view={
                            {
                                latitude: props.unitData.latitude,
                                longitude: props.unitData.longitude,
                                zoom: 14,
                            }
                        }></MapView>
                    </div>
                   
                </div>
                
            )
        }
        {
            unitDetailsTab === "user" && (
                <div className="unit-details-container">
                    <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                    <p>{props.unitData.user.fName}</p>
                    <p>{props.unitData.user.lName}</p>
                    <p>{props.unitData.user.email}</p>
                    <p>{props.unitData.user.phoneNumber}</p>
                    <p>{props.unitData.start}</p>
                    <button>View Previous Users</button>
                </div>
            )
        }
        {
            unitDetailsTab === "activity" && (
                <div className="unit-details-container">
                    <p></p>
                </div>
            )
        }
                                  
    </div>
    )
}

export default UnitDetails