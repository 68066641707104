
import { getAuth, onAuthStateChanged ,GoogleAuthProvider, signInWithEmailAndPassword, signInWithRedirect, signOut, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, updateDoc, deleteDoc, getDocs, query, collection, QuerySnapshot, where, getDoc, getDb } from "firebase/firestore"; 
import { initializeApp } from "firebase/app";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCY6QUDZ41zmb8ilKmySXR0BbNc91BqLKk",
  authDomain: "gridlock-2f403.firebaseapp.com",
  projectId: "gridlock-2f403",
  storageBucket: "gridlock-2f403.appspot.com",
  messagingSenderId: "820264367565",
  appId: "1:820264367565:web:2e994c0723808dec720084",
  measurementId: "G-MV36GTTZ47"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const addUserOrg = async (uid, company, name, phoneNumber, email) => {
    //add new org and user
    //get the length of the organization collection

    const orgRef = collection(db, "organizations")
    const orgSnapshot = await getDocs(orgRef)
    const newOrgNumber = orgSnapshot.docs.length + 1

    const orgScheme = {
        name: company,
        orgId: newOrgNumber,
        users: [uid],
        startDate: Date.now(),
    }
    const userScheme = {
        uid: uid,
        email: email,
        displayName: name,
        phoneNumber: phoneNumber,
        company: company,
        orgId: newOrgNumber,
        startDate: Date.now(),
    }
    await setDoc(doc(db, "users", uid), userScheme)
    await setDoc(doc(db, "organizations", `org_${newOrgNumber}`), orgScheme)
}

export const getUser = async (uid) => {
    const docRef = doc(db, "users", uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return null
    }
}

export const checkUser = async (uid) => {
    const docRef = doc(db, "users", uid)
    console.log(docRef)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        return true
    } else {
        return false
    }
}

export const updateOrgSettings = async (orgId, settings) => {
    const docRef = doc(db, "organizations", `org_${orgId}`)
    //settings is a object, merge the two objects
    await updateDoc(docRef, settings)
    
}
