import './HexagonBackground.css'

function HexagonBackground() {
    return (
    <div className="hex-container">
		<div className="row">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
      
		</div>
		<div className="row altline2">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
      
		</div>
		<div className="row line2">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
		</div>
		<div className="row altline3">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
		</div>
		<div className="row line3">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
		</div>
		<div className="row altline4">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
		</div>
		<div className="row line4">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
		</div>
		<div className="row altline5">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
		</div>
		<div className="row line5">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
		</div>
		<div className="row">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
		</div>
		<div className="row">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
		</div>
		<div className="row">
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
			<div className="hexagon"></div>
		</div>
	</div>
  
    )
}

export default HexagonBackground
