import "./SideMenu.css"
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHouse, faUsers, faUser, faLocationPinLock, faLock, faCog, faList, faMapLocationDot, faSitemap, faKey} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react"
import logo from '../Img/logo1.png'
import { useNavigate } from "react-router-dom"

function SideMenu(props) {
    console.log(props)
    const [activeTab, setActiveTab] = useState('Units')
    const navigate = useNavigate()

    const changeTab = (e) => {
        //get the name
        console.log(e.target)
        var tab = e.target.getAttribute("name")

        if (!tab) {
            //recursively get the parent until you get the name
            while (!tab){
                e.target = e.target.parentElement
                tab = e.target.getAttribute("name")
                console.log(tab)
            }
            const parent = e.target.parentElement
            const buttons = parent.children
            for (let i = 0; i < buttons.length; i++){
                buttons[i].classList.remove("side-menu-active")
            }
            e.target.classList.add("side-menu-active")
            navigate('/' + tab.toLowerCase())
        }
        else {
            if (tab === activeTab){
                return
            }
            else {
                const parent = e.target.parentElement
                const buttons = parent.children
                for (let i = 0; i < buttons.length; i++){
                    buttons[i].classList.remove("side-menu-active")
                }
                e.target.classList.add("side-menu-active")
                navigate('/' + tab.toLowerCase())
            }
        }

    }

    return(
        <>
            <nav className="side-menu">
                <section className="side-menu-split">
                    <img src={logo}></img>
                    <div 
                        className={props.page==="Home" ? "side-menu-active": ""}
                        name="Home"
                        onClick={(e) => changeTab(e)}>
                    <FontAwesomeIcon icon={faHouse}/>
                    ADMIN HQ
                    </div>
                    <div 
                        name="Locks"
                        className={props.page==="Locks" ? "side-menu-active": ""}
                        onClick={(e) => changeTab(e)}>
                        <FontAwesomeIcon icon={faLock}/>
                        LOCKS
                    </div>
                    <div 
                        name="Map"
                        className={props.page==="Map" ? "side-menu-active": ""}
                        onClick={(e) => changeTab(e)}>
                        <FontAwesomeIcon icon={faMapLocationDot}/>
                        MAP VIEW
                    </div>
                    <div 
                        name="Users"
                        className={props.page==="Users" ? "side-menu-active": ""}
                        onClick={(e) => changeTab(e)}>
                        <FontAwesomeIcon icon={faUsers}/>
                        USERS
                    </div>
                    <div 
                        name="Pins"
                        className={props.page==="Pins" ? "side-menu-active": ""}
                        onClick={(e) => changeTab(e)}>
                        <FontAwesomeIcon icon={faKey}/>
                        PINS
                    </div>
                    <div 
                        name="Activity"
                        className={props.page==="Activity" ? "side-menu-active": ""}
                        onClick={(e) => changeTab(e)}>
                        <FontAwesomeIcon icon={faList}/>
                        ACTIVITY
                    </div>
                </section>
                <section className="side-menu-split">
                    <p>{props.company}</p>
                    <p>Org {props.orgId}</p>
                    <div 
                        name="Account"
                        className={props.page==="Account" ? "side-menu-active": ""}
                        onClick={(e) => changeTab(e)}>
                        <FontAwesomeIcon icon={faUser}/>
                        ACCOUNT
                    </div>
                    <div 
                        name="Settings"
                        className={props.page==="Settings" ? "side-menu-active": ""}
                        onClick={(e) => changeTab(e)}>
                        <FontAwesomeIcon icon={faCog}/>
                        SETTINGS
                    </div>
                </section>
            </nav>
        </>
    )
}

export default SideMenu