
import { Routes, Route} from "react-router-dom";
import './App.css';
import AdminController from './Views/AdminController';
import { useEffect } from 'react';
import { saveData, getData } from './Components/LocalStorageFunctions';
import { useState } from 'react';
import LoginPage from './Views/LoginPage';
import ProtectedRoute from './Auth/ProtectedRoute';
import SignUpPage from './Views/SignUpPage';
import { AuthContextProvider } from './Auth/AuthContext';
import BaseURLRoute from './Auth/BaseURLRoute';



function App() {
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    //check if demo data exists locally
    const DEMO_MODE = process.env.REACT_APP_DEMO_MODE;
    console.log(DEMO_MODE)
    const DEMO_TOTAL_UNITS = process.env.REACT_APP_DEMO_TOTAL_UNITS;
    const DEMO_TOTAL_GRIDLOCK_UNITS = process.env.REACT_APP_DEMO_TOTAL_GRIDLOCK_UNITS;
    const DEMO_TOTAL_AVAILABLE_UNITS = process.env.REACT_APP_DEMO_TOTAL_AVAILABLE_UNITS;
    const DEMO_TOTAL_CUSTOMERS = process.env.REACT_APP_DEMO_TOTAL_CUSTOMERS;
    const DEMO_TOTAL_BATTERY_GRANULARITY = process.env.REACT_APP_DEMO_TOTAL_BATTERY_GRANULARITY;
    const DEMO_ACTIVITY_GRANULARITY = process.env.REACT_APP_DEMO_ACTIVITY_GRANULARITY;  
    const demoData = {
          units: DEMO_TOTAL_UNITS,
          gridlocks: DEMO_TOTAL_GRIDLOCK_UNITS,
          available: DEMO_TOTAL_AVAILABLE_UNITS,
          customers: DEMO_TOTAL_CUSTOMERS,
          battery: DEMO_TOTAL_BATTERY_GRANULARITY,
          activities: DEMO_ACTIVITY_GRANULARITY
        }
    saveData("demoData", demoData) //ensure this processes before rendering main app
    setLoading(false)



  }, [])
  
  return (
    <AuthContextProvider>
      <Routes>

        {/* public */}
        <Route path="/" element={<BaseURLRoute></BaseURLRoute>}></Route>
        <Route path="/login" element={<LoginPage></LoginPage>}></Route>
        <Route path="/signup" element={<SignUpPage></SignUpPage>}></Route>
        <Route path="/signup-google" element={<SignUpPage google={true}></SignUpPage>}></Route>
        
        {/* private */}
        <Route path="/home" element={<ProtectedRoute><AdminController page="Home"/></ProtectedRoute>}/>
        <Route path="/home/welcome" element={<ProtectedRoute><AdminController page="Home" welcome={true}/></ProtectedRoute>}/>
        
        <Route path="/locks" element={<ProtectedRoute><AdminController page="Locks"/></ProtectedRoute>} />
        <Route path="/list" element={<ProtectedRoute><AdminController page="Locks" view="list"/></ProtectedRoute>} />
        
        <Route path="/locks/unit/:id" element={<ProtectedRoute><AdminController page="Locks"/></ProtectedRoute>} />
        <Route path="/list/unit/:id" element={<ProtectedRoute><AdminController page="Locks" view="list"/></ProtectedRoute>} />
        <Route path="/locks/facility/:id" element={<ProtectedRoute><AdminController page="Locks"/></ProtectedRoute>} />
        <Route path="/users" element={<ProtectedRoute><AdminController page="Users"/></ProtectedRoute>} />
        <Route path="/activity" element={<ProtectedRoute><AdminController page="Activity"/></ProtectedRoute>} />
        <Route path="/pins" element={<ProtectedRoute><AdminController page="Pins"/></ProtectedRoute>} />
        <Route path="/buildings" element={<ProtectedRoute><AdminController page="Buildings"/></ProtectedRoute>} />
        <Route path="/Account" element={<ProtectedRoute><AdminController page="Account"/></ProtectedRoute>} />
        <Route path="/settings" element={<ProtectedRoute><AdminController page="Settings"/></ProtectedRoute>} />
        <Route path="/map" element={<ProtectedRoute><AdminController page="Map"/></ProtectedRoute>} />
        <Route path="/map/:locationId" element={<ProtectedRoute><AdminController page="Map"/></ProtectedRoute>} />
      </Routes>
    </AuthContextProvider>
  );
}


export default App;
