import './UnitList.css'
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import UnitDetails from './UnitDetails'
import { getData } from './LocalStorageFunctions'
import { useNavigate, useParams } from 'react-router-dom'
import BatteryPercentage from './BatteryPercentage'

const UnitList = (props) => {
    console.log(props)
    const {id} = useParams();
    const navigate = useNavigate()
    const [viewDetails, setViewDetails] = useState(false)
    const [selectedUnit, setSelectedUnit] = useState(false)
    const [units, setUnits] = useState([])

    const handleUnitListClick = (e, unit) => {
        console.log(unit)
        setViewDetails(true)
        setSelectedUnit(unit)
        props.selectUnit(unit)
        navigate(`/list/unit/${unit.id}`)

    }

    const exitDetails = () => {
        setViewDetails(false)
        setSelectedUnit(null)
        navigate(`/list`)
    }

    useEffect(() => {
        const data = getData('units');
        if (data) {
          setUnits(data);
        }
        if (id !== undefined) {
            //find the unit with id === id
            //get the index of that unit
            console.log(id)
            const index = data.findIndex((unit) => {
                return unit.id === id
                }
            )
            console.log(index)
            setSelectedUnit(data[index])
            setViewDetails(true)
        }

    }, []);

    return(
        <div className="unit-list">
            <table>
                <thead>
                    <tr>
                        <th>Unit Number</th>
                        <th>Status</th>
                        <th>Lock Number</th>
                        <th>Lock Battery</th>
                        <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        units.map((unit) => {
                            return(
                                <tr onClick={(e) => handleUnitListClick(e, unit)} key={unit.id}>
                                    <td>{unit.name}</td>
                                    <td>{unit.status}</td>
                                    <td>{unit.id}</td>
                                    <td><BatteryPercentage battery={unit.lockBattery}></BatteryPercentage></td>
                                    <td>{unit.building} {unit.floor} {unit.facility}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                        

            </table>
                    
            {
                viewDetails &&
                <div className="unit-list-details">
                    <div className="unit-list-details-exit" onClick={() => exitDetails()}>

                    </div>
                    <div className="unit-list-details-content">
                        <UnitDetails unitData={selectedUnit}></UnitDetails>
                    </div>
                </div>  
            }
            
        </div>
    )
}

export default UnitList;