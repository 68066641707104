import { Route, Navigate, useNavigate } from "react-router-dom";
import { auth } from "../FirebaseFunctions";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import Loader from "../Components/Loader";

const ProtectedRoute = ({ children }) => {
    const [user, setUser] = useState(null);
    let navigate = useNavigate()
    
    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
            console.log(currentUser)
            setUser(currentUser);
            }
            else {
            setUser(false);
            console.log('yuh')
            navigate('/login')
            }
        })}
    , []);
    
    
    return (user ? children : <div style={{"display":"flex", "justifyContent": "center"}}><Loader></Loader></div>);
}

export default ProtectedRoute;
