import './HomeInfo.css'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { db } from '../FirebaseFunctions'
import { doc, setDoc, query, collection, where, getDocs, orderBy, limit } from "firebase/firestore"; 
import {faUserCheck, faUserLargeSlash, faPen, faLock, faLockOpen, faBattery, faBattery0, faBattery2, faBattery3, faBattery4, faBattery5, faUser} from '@fortawesome/free-solid-svg-icons'
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { useEffect } from "react"
import Graph from './Graph'
import Widget from './Widget'
import LineGraph from './LineGraph'
import PieGraph from './PieGraph'
import BarGraph from './BarGraph'
import Loader from './Loader'

function HomeInfo(props) {
    console.log(props)
    const site = process.env.REACT_APP_INGEST_API
    const navigate = useNavigate()
    const [available, setAvailable] = useState(0)
    const [unavailable, setUnavailable] = useState(0)
    const [activity, setActivity] = useState([{
        "id": "Locks",
        "data": [],
    }])
    const [pieData, setPieData] = useState(null)

    const [events, setEvents] = useState([])
    const [timeFrame, setTimeFrame] = useState(props.selectedTimeFrame ? props.selectedTimeFrame : {icon: "1h", text: "Past Hour"})

    const timeframe = "1h";
    const [aggregateBy, setAggregateBy] = useState(10) //in minutes
    const [timeRange, setTimeRange] = useState(200)//in minutes

    const [loader, setLoader] = useState(true)

    const graphData = [
        {
          "id": "Locks",
          "data": [
            {
              "x": "plane",
              "y": 17,
            },
            {
              "x": "helicopter",
              "y": 117
            },
            {
              "x": "boat",
              "y": 42
            },
            {
              "x": "train",
              "y": 282
            },
            {
              "x": "subway",
              "y": 169
            },
            {
              "x": "bus",
              "y": 220
            },
            {
              "x": "car",
              "y": 229
            },
            {
              "x": "moto",
              "y": 25
            },
            {
              "x": "bicycle",
              "y": 28
            },
            {
              "x": "horse",
              "y": 289
            },
            {
              "x": "skateboard",
              "y": 116
            },
            {
              "x": "others",
              "y": 78
            }
          ]
        },
        
        {
          "id": "Unlocks",
          "data": [
            {
              "x": "plane",
              "y": 55
            },
            {
              "x": "helicopter",
              "y": 21
            },
            {
              "x": "boat",
              "y": 25
            },
            {
              "x": "train",
              "y": 138
            },
            {
              "x": "subway",
              "y": 197
            },
            {
              "x": "bus",
              "y": 289
            },
            {
              "x": "car",
              "y": 235
            },
            {
              "x": "moto",
              "y": 204
            },
            {
              "x": "bicycle",
              "y": 135
            },
            {
              "x": "horse",
              "y": 203
            },
            {
              "x": "skateboard",
              "y": 201
            },
            {
              "x": "others",
              "y": 58
            }
          ]
        },
        
      ]
    const demoPieData =[
        {
          "id": "lisp",
          "label": "lisp",
          "value": 48,
          "color": "hsl(92, 70%, 50%)"
        },
        {
          "id": "javascript",
          "label": "javascript",
          "value": 419,
          "color": "hsl(298, 70%, 50%)"
        },
        {
          "id": "stylus",
          "label": "stylus",
          "value": 426,
          "color": "hsl(272, 70%, 50%)"
        },
        {
          "id": "hack",
          "label": "hack",
          "value": 563,
          "color": "hsl(315, 70%, 50%)"
        },
        {
          "id": "haskell",
          "label": "haskell",
          "value": 29,
          "color": "hsl(330, 70%, 50%)"
        }
      ]
    const barData = [
        {
          "country": "AD",
          "hot dog": 173,
          "hot dogColor": "hsl(221, 70%, 50%)",
          "burger": 30,
          "burgerColor": "hsl(295, 70%, 50%)",
          "sandwich": 117,
          "sandwichColor": "hsl(288, 70%, 50%)",
          "kebab": 200,
          "kebabColor": "hsl(351, 70%, 50%)",
          "fries": 156,
          "friesColor": "hsl(54, 70%, 50%)",
          "donut": 140,
          "donutColor": "hsl(281, 70%, 50%)"
        },
        {
          "country": "AE",
          "hot dog": 64,
          "hot dogColor": "hsl(90, 70%, 50%)",
          "burger": 93,
          "burgerColor": "hsl(169, 70%, 50%)",
          "sandwich": 49,
          "sandwichColor": "hsl(172, 70%, 50%)",
          "kebab": 166,
          "kebabColor": "hsl(89, 70%, 50%)",
          "fries": 49,
          "friesColor": "hsl(348, 70%, 50%)",
          "donut": 115,
          "donutColor": "hsl(84, 70%, 50%)"
        },
        {
          "country": "AF",
          "hot dog": 69,
          "hot dogColor": "hsl(225, 70%, 50%)",
          "burger": 173,
          "burgerColor": "hsl(48, 70%, 50%)",
          "sandwich": 70,
          "sandwichColor": "hsl(183, 70%, 50%)",
          "kebab": 26,
          "kebabColor": "hsl(101, 70%, 50%)",
          "fries": 193,
          "friesColor": "hsl(331, 70%, 50%)",
          "donut": 192,
          "donutColor": "hsl(120, 70%, 50%)"
        },
        {
          "country": "AG",
          "hot dog": 46,
          "hot dogColor": "hsl(7, 70%, 50%)",
          "burger": 74,
          "burgerColor": "hsl(242, 70%, 50%)",
          "sandwich": 189,
          "sandwichColor": "hsl(19, 70%, 50%)",
          "kebab": 53,
          "kebabColor": "hsl(222, 70%, 50%)",
          "fries": 53,
          "friesColor": "hsl(296, 70%, 50%)",
          "donut": 61,
          "donutColor": "hsl(159, 70%, 50%)"
        },
        {
          "country": "AI",
          "hot dog": 141,
          "hot dogColor": "hsl(274, 70%, 50%)",
          "burger": 19,
          "burgerColor": "hsl(243, 70%, 50%)",
          "sandwich": 128,
          "sandwichColor": "hsl(277, 70%, 50%)",
          "kebab": 2,
          "kebabColor": "hsl(166, 70%, 50%)",
          "fries": 130,
          "friesColor": "hsl(295, 70%, 50%)",
          "donut": 178,
          "donutColor": "hsl(291, 70%, 50%)"
        },
        {
          "country": "AL",
          "hot dog": 170,
          "hot dogColor": "hsl(209, 70%, 50%)",
          "burger": 13,
          "burgerColor": "hsl(76, 70%, 50%)",
          "sandwich": 25,
          "sandwichColor": "hsl(39, 70%, 50%)",
          "kebab": 40,
          "kebabColor": "hsl(288, 70%, 50%)",
          "fries": 11,
          "friesColor": "hsl(255, 70%, 50%)",
          "donut": 184,
          "donutColor": "hsl(26, 70%, 50%)"
        },
        {
          "country": "AM",
          "hot dog": 9,
          "hot dogColor": "hsl(34, 70%, 50%)",
          "burger": 10,
          "burgerColor": "hsl(276, 70%, 50%)",
          "sandwich": 90,
          "sandwichColor": "hsl(41, 70%, 50%)",
          "kebab": 73,
          "kebabColor": "hsl(206, 70%, 50%)",
          "fries": 129,
          "friesColor": "hsl(232, 70%, 50%)",
          "donut": 125,
          "donutColor": "hsl(173, 70%, 50%)"
        }
      ]
    const timeFrameToValue = (timeFrame) => {
        let timeRange;
        let aggregateBy;
        switch (timeFrame) {
          case "30m":
            timeRange = 30;
            aggregateBy = 1;
            break;
          case "1h":
            timeRange = 60;
            aggregateBy = 3;
          break;
        case "4h":
          timeRange = 240;
          aggregateBy = 12;
          break;
        case "1d":
          timeRange = 1440;
          aggregateBy = 60;
          break;
        case "2d":
          timeRange = 2880;
          aggregateBy = 120;
          break;
        case "4d":
          timeRange = 5760;
          aggregateBy = 240;
          break;
        case "1w":
          timeRange = 10080;
          aggregateBy = 420;
          break;
        case "1mo":
          timeRange = 43200;
          aggregateBy = 1800;
          break;
        default:
          timeRange = 60;
          aggregateBy = 3;
          break;
        
        
        }
        return {timeRange, aggregateBy}
      }

    useEffect(() => {
        setPieData(getPieData())
        let eventSource;
        
        const currentTime = Math.floor(Date.now() / 1000)
        const startTime = currentTime - (timeRange * 60)

        const getEvents = async (timeRange) => {
          const q = query(collection(db, `org_${props.orgId}`, "activity_events", "all"), orderBy("timestamp", "desc"), limit(20));
          const querySnapshot = await getDocs(q);
          const events = []
          querySnapshot.forEach((doc) => {
              events.push(doc.data())
              });
              setEvents(events)

          if (events.length > 0) {
              console.log(events)
              let {timeRange, aggregateBy} = timeFrameToValue(props.selectedTimeFrame.icon)
              console.log(timeRange, aggregateBy)
              setActivity(aggregateEvents(events, aggregateBy, timeRange))
          }
          else{
              console.log("no events")
          }
        }
        getEvents()

        // Clean up the event source connection when the component unmounts
        return () => {
            if (eventSource) {
              eventSource.close();
              eventSource = null;
            }
          };
    }
    ,[props.selectedTimeFrame])

    
    const convertToBrowserTime = (timestamp) => {
        //take a timestamp like 1688501349
        //convert it to a date object
        //return the date object
        const date = new Date(timestamp * 1000)
        const hour = date.getHours()
        const minutes = date.getMinutes()
        const seconds = date.getSeconds()
        const time = hour + ":" + minutes + ":" + seconds
        return time

    }

    function aggregateEvents(events, aggregateBy, timeRange) {
      console.log(aggregateBy)
      const aggregated = {};
      const result = [];
      //sort by timestamp
      events.sort((a, b) => a.timestamp - b.timestamp);
      

      //go through each event and sort it by the action value
      events.forEach((event) => {
        if (!aggregated[event.action]) {
          aggregated[event.action] = [];
        }
        aggregated[event.action].push(event);
      });

      //create an array for each action type that is an array of objects with x, y
      //x is the time, y is the number of events
      //x increments by aggregateBy

      for (const action in aggregated) {
        const actionEvents = aggregated[action];
        const aggregatedActionEvents = [];
        let currentTime = Math.floor(Date.now() / 1000)
        let startTime = currentTime - (timeRange * 60)
        let endTime = currentTime

        //create an array of objects with x, y
        //x is the time, y is the number of events
        //x increments by aggregateBy
        for (let i = startTime; i < endTime; i += aggregateBy * 60) {
          const count = actionEvents.filter(
            (event) => event.timestamp >= i && event.timestamp < i + aggregateBy * 60
          ).length;
          aggregatedActionEvents.push({
            x: minuteToTime(i / 60),
            y: count,
          });
        }
        //add object to the result array
        result.push({
          id: action,
          data: aggregatedActionEvents,
        });

      }
      console.log(result)
      return result;
    }

    function minuteToTime(minute) {
      //turn into date object
      const date = new Date(minute * 60 * 1000);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

  }    
  
    const getUnitAvailability = () => {
        const units = props.unitData
        let available = 0
        let unavailable = 0
        units.forEach((unit) => {
            if (unit.status === "available") {
                available += 1
            }
            else{
                unavailable += 1
            }
        })
        return {available, unavailable}
    }

    const getPieData = () => {
        const {available, unavailable} = getUnitAvailability()
        const pieData = [
            {
              "id": "available",
              "label": "Available",
              "value": available,
              "color": "hsl(92, 70%, 50%)"
            },
            {
              "id": "unavailable",
              "label": "Unavailable",
              "value": unavailable,
              "color": "hsl(298, 70%, 50%)"
            },
          ]
        return pieData
    }

    return (
        <div className="home-box">
                        <header>
                          <h2 className="welcome"></h2>
                        </header>
                        <section>
                            <h2>Activity</h2>
                            <div className="graph-divider">
                            <div className="graph-container">
                              <LineGraph data={activity}></LineGraph>
                            </div>
                                




                            </div>
                        </section>
                        <section>
                            <h2>Unit Status</h2>
                            <div className="home-info-container">
                                <div className="home-info-box">
                                    <h3>
                                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                        Locks
                                    </h3>
                                    <span>
                                        <h1>{(props.unitData).length}</h1>
                                        <p>Locks on {(props.unitData).length} Units</p>
                                    </span>
                                    <div className="pie-chart-container">
                                        <PieGraph data={pieData}></PieGraph>
                                    </div>
                                    

                                    
                                </div>
                                <div className="home-info-box">
                                    <h3>
                                        <FontAwesomeIcon icon={faLockOpen}></FontAwesomeIcon>
                                        Unit Interactions
                                    </h3>
                                    <span>
                                        <h1>35</h1>
                                        <p>Unlocks</p>
                                    </span>
                                    <div className="pie-chart-container">
                                        <BarGraph data={barData}></BarGraph>
                                    </div>
                                    
                                </div>
                                {
                                    /*
                                    <div className="home-info-box">
                                    <h3>
                                        <FontAwesomeIcon icon={faUserCheck}></FontAwesomeIcon>
                                        Customers
                                    </h3>
                                    <span>
                                        <h1>135</h1>
                                        <p>Total</p>
                                    </span>
                                </div>
                                    */
                                }
                                
                            </div>
                        </section>
                        
        </div>  
    )
}


export default HomeInfo