import './Loader.css'


const Loader = () => {
    return (
        <div className="socket">
			<div className="hex c0">
				<div className="hex-brick"></div>
				<div className="hex-brick hlx2"></div>
				<div className="hex-brick hlx3"></div>
			</div>
			<div className="hex c1 r1">
				<div className="hex-brick"></div>
				<div className="hex-brick hlx2"></div>
				<div className="hex-brick hlx3"></div>
			</div>
			<div className="hex c2 r1">
				<div className="hex-brick"></div>
				<div className="hex-brick hlx2"></div>
				<div className="hex-brick hlx3"></div>
			</div>
			<div className="hex c3 r1">
				<div className="hex-brick"></div>
				<div className="hex-brick hlx2"></div>
				<div className="hex-brick hlx3"></div>
			</div>
			<div className="hex c4 r1">
				<div className="hex-brick"></div>
				<div className="hex-brick hlx2"></div>
				<div className="hex-brick hlx3"></div>
			</div>
			<div className="hex c5 r1">
				<div className="hex-brick"></div>
				<div className="hex-brick hlx2"></div>
				<div className="hex-brick hlx3"></div>
			</div>
			<div className="hex c6 r1">
				<div className="hex-brick"></div>
				<div className="hex-brick hlx2"></div>
				<div className="hex-brick hlx3"></div>
			</div>

		</div>
    )

}

export default Loader;