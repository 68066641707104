import { useState } from "react";
import {faBattery, faBattery0, faBattery2, faBattery3, faBattery4, faBattery5} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './BatteryPercentage.css'

function BatteryPercentage(props) {
    const batteryIcon = (battery) => {
        if (battery <= 9) {
            return [faBattery0, "battery0"]
        } else if (battery > 9 && battery < 19) {
            return [faBattery2, "battery2"]
        } else if (battery >= 20 && battery <= 29) {
            return [faBattery3, "battery3"]
        } else if (battery >= 30 && battery <= 49) {
            return [faBattery4, "battery4"]
        } else {
            return [faBattery5, "battery100"]
        }
    }

    return (
        <span className={batteryIcon(props.battery)[1]}>
            <FontAwesomeIcon className="battery-icon" icon={batteryIcon(props.battery)[0]}></FontAwesomeIcon>
            {props.battery}%
            {
                /* <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon> */
            }
            
        </span>
    )
}

export default BatteryPercentage;