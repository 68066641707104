import './SettingsData.css'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHouse, faUsers, faUser, faLocationPinLock, faLock, faCog, faList} from '@fortawesome/free-solid-svg-icons'
import { saveData, getData } from './LocalStorageFunctions'
import { UserAuth } from '../Auth/AuthContext'


export default function SettingsPage(props) {
    const {logout} = UserAuth()
    const DEMO_MODE = process.env.REACT_APP_DEMO_MODE;
    console.log(DEMO_MODE)
    //How many units?
    //How many of them have the gridlock?
    //How many units have are available? - status
    //set list of longitudes, latitudes, addresses

    //Lock Status
    //How often to recieve lock battery update
    
    //How often to recieve new activities?

    //How many customers?
    const [demoData, setDemoData] = useState({
        units: 0,
        gridlocks: 0,
        available: 0,
        customers: 0,
        battery: 0,
        activities: 0
    })
    const [formState, setFormState] = useState(false)
    const [activeTab, setActiveTab] = useState('demo')
    const handleActiveTab = (e, tab) => {
        const parent = e.target.parentElement
        const buttons = parent.children
        for (let i = 0; i < buttons.length; i++){
            buttons[i].classList.remove("active")
        }
        e.target.classList.add("active")
        setActiveTab(tab)

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        // Do something with the form data, such as making an API call
        console.log(demoData);
        setFormState(true)
        saveData("demoData", demoData)
        };
        
    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(name)
        if ((name === "gridlocks") || (name === "available")) {
            if (value > demoData.units) {
                alert("You can't have more gridlocks/available than units")
                return
            }
        }
        setDemoData((prevData) => ({
          ...prevData,
          [name]: value
        }));

      };

    useEffect(() => {
        const data = getData("demoData")
        console.log(data)
        if (data === null){
            return
        }
        else{
            setDemoData(data)
        }
    }, [])



    return (
        <div className="settings-data-container">
            <nav>
                {
                    DEMO_MODE != true &&
                    <span className="active" onClick={(e) => handleActiveTab(e, "demo")}>Demo Data</span>
                }
                <span onClick={(e) => handleActiveTab(e, "account")}>Account Settings</span>
                <span onClick={(e) => handleActiveTab(e, "lock")}>Lock Settings</span>
                <span onClick={(e) => logout()}>Logout</span>
            </nav>
            
            {
                (activeTab === "demo") && (DEMO_MODE !== true) &&
                <form>
                <div className="settings-data-row">
                    <label>Total Units</label>
                    <div>
                        <input 
                            type="number" 
                            name="units" 
                            min="1" 
                            onChange={handleChange}
                            value={demoData.units}
                            ></input>
                        <span>
                            <p>units</p>
                            <h6>min 1</h6>
                        </span>
                    </div>
                    
                </div>
                <div className="settings-data-row">
                    <label>Gridlock Units</label>
                    <div>
                        <input 
                            type="number" 
                            name="gridlocks" 
                            min="1"
                            onChange={handleChange}
                            value={demoData.gridlocks}
                            
                            ></input>
                        
                        <span>
                            <p>gridlocks</p>
                            <h6>min 1</h6>
                        </span>
                    </div>
                    
                </div>
                <div className="settings-data-row">
                    <label>Available Units</label>
                    <div>
                        <input 
                            type="number" 
                            name="available" 
                            min="0"
                            onChange={handleChange}
                            value={demoData.available}
                        ></input>
                        
                        <span>
                            <p>available</p>
                            <h6>min 0</h6>
                        </span>
                    </div>
                </div>
                <div className="settings-data-row">
                    <label>Customers</label>
                    <div>
                        <input 
                            type="number" 
                            name="customers" 
                            min="1"
                            onChange={handleChange}
                            value={demoData.customers}
                        ></input>
                        
                        <span>
                            <p>count</p>
                            <h6>min 1</h6>
                        </span>
                    </div>
                </div>
                <div className="settings-data-row">
                    <label>Battery Granularity</label>
                    <div>
                        <input 
                            type="number" 
                            name="battery" 
                            min="5"
                            onChange={handleChange}
                            value={demoData.battery}
                        ></input>
                        
                        <span>
                            <p>seconds</p>
                            <h6>min 5/sec</h6>
                        </span>
                    </div>
                </div>
                <div className="settings-data-row">
                    <label>Activity Granularity</label>
                    <div>
                        <input 
                            type="number" 
                            name="activities" 
                            min="5"
                            onChange={handleChange}
                            value={demoData.activities}
                        ></input>
                        <span>
                            <p>seconds</p>
                            <h6>min 5/sec</h6>
                        </span>
                    </div>
                </div>
                <div style={{'display':'flex', 'gap':"10px"}}>
                    <button onClick={() => {localStorage.clear();alert('cleared local storage')}}>Clear Local Storage</button>
                    <button onClick={handleSubmit}>Apply</button>
                </div>
                </form>
                
            }
            {
                activeTab === "account" &&
                <div className="account-settings">

                </div>
            }
            
        </div>
    )

}
